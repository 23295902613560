
/* eslint-disable */
import React, { useState, useCallback, useRef, useMemo, Fragment, useEffect } from 'react';
import ReactFlow, { BaseEdge, EdgeLabelRenderer, getBezierPath, ReactFlowProvider, useNodesState, useEdgesState, Background, Controls, Handle } from 'reactflow';

import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Menu } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query';
import { DevTool } from "@hookform/devtools";
import { useForm, useFieldArray, Controller } from "react-hook-form";


import { useTemp, useWaTemp, useEmp, useWaInter, useWebhook, useFunnel } from '../api/waTemp';

import axios from 'axios';
import ReactDOM from 'react-dom/client';
import DatePicker from "react-datepicker";

import SideModal from './components/SideModal';
import Header from './components/Header';
import SearchBox from './components/SearchBox';
import AddNewButtonTemp from './components/ButtonWa';
import AddNewListTemp from './components/ListWa';
import CondComponet2 from './components/Condition';
import SelectOptions from './components/SelectOptions';
import EditButtonTemp from './components/EditWaButton';
import EditListTemp from './components/EditWaList';
import PreviewComp from './components/PreviewComp';
import ButtonImg from '../../image/button-img.png';
import ListImg from '../../image/list-img.png';
import Spinner from '../../cutomComponent/spinner'
import Handles from '../../cutomComponent/handles'
import SpreadsheetList from '../testing.components';


import Keywords from './channelComp/Keywords';
import WebhookUrls from './channelComp/Webhooks';
import SmsBox from './channelComp/SmsBox';
import VoiceBox from './channelComp/VoiceBox';
import SaveFunnelBox from './channelComp/SaveFunnelBox';


import 'reactflow/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';


const host = "https://oiomni.com/api"
// const host = "https://13.200.85.235/api"


const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
const wa_authKey = localStorage.getItem('wa_auth_key');

const bssp_authKey = "46a57c629565ac350bae4264c3e82d9e"
const defaultViewport: Viewport = { x: 0, y: 0, zoom: 0.7 };

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    localStorage.removeItem('wa_auth_key');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}


export default function NewOne() {
    const { id } = useParams();
    const [formState, setFormState] = useState({});
    const [formSec, setFormSec] = useState({});

    const reactFlowWrapper = useRef(null);
    const [rfInstance, setRfInstance] = useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [flowDetail, setFlowDetail] = useState([]);
    const [refecther, setRefecther] = useState(false);
    const [waPreview, setWaPreview] = useState([false, "", ""]);
    const [flowPreview, setFlowPreview] = useState(false);
    const [parentZero, setParentZero] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState([false, ""]);


    const { register: flow, handleSubmit: FlowSubmit, control: FlowControl, setValue, setFocus, clearErrors, formState: { errors: flowErr } } = useForm()

    const tempQuery = useTemp();
    const waEmployee = useEmp();
    const waInterQuery = useWaInter();
    const waTempQuery = useWaTemp();
    const webhookQuery = useWebhook();
    const funnelQuery = useFunnel();


    const userDetailQuery = useQuery({
        queryKey: ['userDetails'],
        queryFn: async () => {
            try {
                const response = await axios.get(host + '/user_details', headers);
                console.log(response.data.data)
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },
    })


    const bsspSender = useQuery({
        queryKey: ['bsspSender'],
        queryFn: async () => {
            try {
                const response = await axios.post('https://sms.bulksmsserviceproviders.com/api/get_senderid_api', { "auth_key": bssp_authKey }, headers);
                console.log(response.data.data)
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },
    })

    const bsspTemp = useQuery({
        queryKey: ['bsspTemp'],
        queryFn: async () => {
            try {
                const response = await axios.post('https://sms.bulksmsserviceproviders.com/api/get_temps_api', { "auth_key": bssp_authKey }, headers);
                console.log(response.data.data)
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },
    })

    if (id) {
        const flowQuery = useQuery({
            queryKey: ['node', 'conn', 'details'],
            queryFn: async () => {
                try {
                    const response = await axios.get(host + '/get_flow/' + id, headers);
                    setFlowDetail(response.data.flow_details)
                    setNodes(response.data.nodes)
                    setEdges(response.data.connection)
                    setRefecther(!refecther)
                    setValue('name', response.data.flow_details.flow_name);
                    setValue('phone_no_id', response.data.flow_details.wa_sender);
                    return response.data;
                } catch (error) {
                    throw error;
                }
            },
            refetchOnWindowFocus: false,
        })
    }

    useEffect(() => {
        setValue('name', flowDetail.flow_name);
        setFocus('name')
        setValue('phone_no_id', flowDetail.wa_sender);
        setFocus('phone_no_id')

    }, [setValue, setFocus]);


    if (!token) noAuth()
    const onDelNode = (nodeId) => {
        let t = []
        const getRecursiveChildren = (id, allNodes) => {
            let chiled = [];
            allNodes.map((x, i) => {
                if (x.data.value.parent == id) {
                    chiled.push(x.id)
                    chiled.push(...getRecursiveChildren(x.id, allNodes))
                }
            });
            return chiled;
        }

        function nodeFun(nds) {
            nds.map((el) => {
                if (el.id == nodeId) {
                    t.push(el.id)
                    if (id) {
                        console.log(id, t)
                        axios.post(host + "/node_del", { "flow_id": id, "nodes": [el.id] }, headers)
                            .then(response => {
                                if (response.data.status == 200) {
                                    console.log(response.data)
                                    toast.success(response.data.message)
                                }
                            })
                            .catch(error => {
                                if (error.response) {
                                    if (error.response.status === 403) {
                                        Object.values(error.response.data.message).map(val => {
                                            val.map(i => {
                                                toast.error(i)
                                                console.log(i)
                                            })
                                        })
                                    }
                                }
                            })
                    }
                }
            });
        }

        setNodes(nds => {
            nodeFun(nds)
            const allNew = nds.filter(node => !t.includes(node.id))
            return allNew
        })
        setEdges(eds => eds.filter(edge => !(t.includes(edge.source) || t.includes(edge.target))))
        setRefecther(!refecther)
        setShowConfirmation([false, ""])
    }


    const CustomNode = (event) => {
        const id = event.id;
        const type = event.data.value.type
        if (type == "on_message") {
            setParentZero(true)
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <Keywords setNodes={setNodes} apisData={event.data.value.api} id={id} />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`} onConnect={(params) => onConnect({ ...params, "cond": "all", datas: event })}></Handle>
                </Fragment>
            )
        } else if (type == "on_webhook") {
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <WebhookUrls setNodes={setNodes} apisData={event.data.value.api} id={id} webhookQuery={webhookQuery} />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`}></Handle>
                </Fragment>
            )
        }
        else if (type == "whatsapp" || type == "pre_whatsapp" || type == "edit_whatsapp") {
            return <WADesign datas={event} typo={type} />
        }

        else if (type == "on_spreedsheet") {
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <SpreadsheetList setNodes={setNodes} id={id} />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`}></Handle>
                </Fragment>
            )
        }


        else if (type == "google_sheet") {
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <SpreadsheetList setNodes={setNodes} id={id} />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`}></Handle>
                </Fragment>
            )
        }



        else if (type == "agent_assign") {
            const EmpList = () => {
                const agent_assign = (val) => {
                    setNodes(nds => nds.map(node => {
                        if (node.id == id) {
                            node.data.value.api = { "agentDetails": val, "auth_key": wa_authKey }
                        }
                        return node;
                    }))
                    setRefecther(!refecther)

                }

                if (event.data.value.api && event.data.value.api.agentDetails && event.data.value.api.agentDetails != "") {
                    return (
                        <Fragment>
                            {
                                Object.keys(waEmployee.data.data).map(i => {
                                    return waEmployee.data.data[`${i}`].map(x => {
                                        if (x.empid == event.data.value.api.agentDetails) {
                                            return <h5>Assigned Agent - {x.emp_name}({i})</h5>
                                        }
                                    })
                                })
                            }

                            <select className="form-control" onChange={(e) => agent_assign(e.target.value)}>
                                <option value="" disabled >Select Agent</option>
                                {
                                    Object.keys(waEmployee.data.data).map(i => {
                                        return waEmployee.data.data[`${i}`].map(x => {
                                            if (x.empid == event.data.value.api.agentDetails) {
                                                return <option selected value={x.empid}>{x.emp_name}({i})</option>
                                            } else {
                                                return <option value={x.empid}>{x.emp_name}({i})</option>
                                            }
                                        })
                                    })
                                }
                            </select>
                        </Fragment>
                    )

                } else {
                    return (
                        <select className="form-control" onChange={(e) => agent_assign(e.target.value)}>
                            <option value="" disabled selected>Select Agent</option>
                            {Object.keys(waEmployee.data.data).map(i => waEmployee.data.data[`${i}`].map(x => <option value={x.empid}>{x.emp_name}({i})</option>))}
                        </select>
                    )
                }
            }

            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <div className="card-body mt-0 ">
                                <div className="contact_add_div">
                                    <div className="mx-auto">
                                        <EmpList />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`}></Handle>
                </Fragment>
            )
        }

        else if (type == "wa_message") {
            const MsgBody = () => {
                const [refresher, setRefresher] = useState(false)
                const waMsgSet = (vals) => {
                    setNodes(nds => nds.map(node => {
                        if (node.id == id) {
                            node.data.value.api = { "msg_type": "text", "data": vals, "auth_key": wa_authKey }
                        }
                        return node;
                    }))
                    setRefecther(!refecther)
                    setRefresher(!refresher)
                }

                if (event.data.value.api && event.data.value.api.msg_type && event.data.value.api.msg_type != "") {
                    return <textarea className="form-control" placeholder="Enter Message" defaultValue={event.data.value.api.data} onBlur={(e) => waMsgSet(e.target.value)}></textarea>
                } else {
                    return <textarea className="form-control" placeholder="Enter Message" onBlur={(e) => waMsgSet(e.target.value)}></textarea>
                }
            }
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <div className="card-body mt-0 ">
                                <div className="contact_add_div">
                                    <div className="mx-auto">
                                        <div className="body_teml mt-2">
                                            <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                                            <div className="body_enter_text_box"><MsgBody /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`}></Handle>
                </Fragment>
            )
        }

        else if (type == "on_attribute") {
            setParentZero(true)
            let attribute_arr = [
                // { "vals": "support-status-0", "text": "Open" },
                // { "vals": "support-status-1", "text": "Complete" },
                // { "vals": "support-status-2", "text": "In Progress" },
                // { "vals": "support-priority-0", "text": "Low" },
                // { "vals": "support-priority-1", "text": "Medium" },
                // { "vals": "support-priority-2", "text": "High" },

                { "vals": "sales-status-Not Interested", "text": "Not Interested" },
                { "vals": "sales-status-Interested", "text": "Interested" },
                { "vals": "sales-status-Complete", "text": "Client" },
                { "vals": "sales-lead-Hot", "text": "Hot" },
                { "vals": "sales-lead-Warm", "text": "Warm" },
                { "vals": "sales-lead-Cold", "text": "Cold" },
            ]

            function Attribute() {
                const [refresher, setRefresher] = useState(false);
                const { register: att_reg, handleSubmit: att_handle, formState: { errors: att_err } } = useForm({
                    defaultValues: { attri: event.data.value.api.attributeDetails }
                });

                const att_assign = (val) => {
                    setNodes(nds => nds.map(node => {
                        if (node.id === id) {
                            node.data.value.api = { "attributeDetails": val, "auth_key": wa_authKey };
                        }
                        return node;
                    }));
                    setRefresher(!refresher);
                };
                return (
                    <div className="card-body mt-0">
                        <div className="contact_add_div">
                            <div className="mx-auto">
                                <select {...att_reg("attri", { required: true, onChange: (e) => att_assign(e.target.value) })} className="form-control">
                                    <option value="" disabled>Select Attribute</option>
                                    {attribute_arr.map(x => <option key={x.vals} value={x.vals}>{x.text}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                )
            }
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <Attribute />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`}></Handle>
                </Fragment>
            )
        }

        else if (type == "on_new_funnel" || type == "on_funnel" || type == "on_edit_funnel") {
            const FunnelBox = () => {
                const [refresher, setRefresher] = useState(false)
                const funnelNumberCol = (apiStrut) => {
                    function funnelNumberSet(values) {
                        setNodes(nds => nds.map(node => {
                            if (node.id == id) {
                                node.data.value.api.phone_number_col = values
                            }
                            return node;
                        }))
                        setRefecther(!refecther)
                        setRefresher(!refresher)
                    }
                    return (
                        <select className="form-select" onChange={(e) => funnelNumberSet(e.target.value)}>
                            <option value="" disabled selected={!apiStrut.phone_number_col}>Select Phone Number Column</option>
                            {apiStrut.Columns.map(x => (
                                <option
                                    key={`col_${x.column_id}`} value={`col_${x.column_id}`}
                                    selected={apiStrut.phone_number_col == `col_${x.column_id}`}
                                >
                                    {x.column_name}
                                </option>
                            ))}
                        </select>
                    );
                }

                function FunnelSet(values) {
                    setNodes(nds => nds.map(node => {
                        if (node.id == id) {
                            node.data.value.api.funnelDetail = values
                            node.data.value.api.Columns = funnelQuery.data.data.col_list
                            node.data.value.columns = [{
                                "column": funnelQuery.data.data.col_list,
                                "type": "on_funnel",
                                "details": { "funnel_id": values }
                            }]
                        }
                        return node;
                    }))
                    setRefecther(!refecther)
                    setRefresher(!refresher)
                }

                const FunnelSch = ({ apiStrut }) => {
                    const myNewRef = useRef()
                    if (type == "on_edit_funnel") {
                        const SchBox = ({ value }) => {
                            if (value == "daily") {
                                return (
                                    <Fragment>
                                        <select>
                                            <option>select Hours</option>
                                            {Array.from(Array(24).keys()).map(item => (<option value={item} >{item}</option>))}
                                        </select>
                                        <select>
                                            <option>select min</option>
                                            {Array.from(Array(60).keys()).map(item => (<option value={item} >{item}</option>))}
                                        </select>
                                    </Fragment>
                                )
                            } else if (value == "montly") {
                                return (
                                    <Fragment>
                                        <select>
                                            <option>select Hours</option>
                                            {Array.from(Array(24).keys()).map(item => (<option value={item} >{item}</option>))}
                                        </select>
                                        <select>
                                            <option>select min</option>
                                            {Array.from(Array(60).keys()).map(item => (<option value={item} >{item}</option>))}
                                        </select>
                                    </Fragment>
                                )
                            } else if (value == "weekly") {
                                return (
                                    <Fragment>
                                        <select>
                                            <option>select Hours</option>
                                            {Array.from(Array(24).keys()).map(item => (<option value={item} >{item}</option>))}
                                        </select>
                                        <select>
                                            <option>select min</option>
                                            {Array.from(Array(60).keys()).map(item => (<option value={item} >{item}</option>))}
                                        </select>
                                    </Fragment>
                                )
                            }
                        }
                        function schTypeChg(value) {
                            setNodes(nds => nds.map(node => {
                                if (node.id == id) {
                                    const ndsSch = node.data.value.api.SchDetail
                                    if (ndsSch && ndsSch.type) {
                                        ndsSch.type = value
                                        ndsSch.schData = {}
                                    } else {
                                        ndsSch = { type: value, schData: {} }
                                    }
                                }
                                return node;
                            }))
                            setRefecther(!refecther)
                            setRefresher(!refresher)
                        }
                        return (
                            <div ref={myNewRef}>
                                <select onChange={(e) => schTypeChg(e.target.value)}>
                                    <option value="daily">Daily</option>
                                    <option value="montly">Montly</option>
                                    <option value="weekly">Weekly</option>
                                </select>
                                <div id="schData"></div>
                            </div>
                        );
                    }
                }

                if (event.data.value.api.funnelDetail && event.data.value.api.funnelDetail != "") {
                    return (
                        <div className="card-body mt-0 ">
                            <div className="contact_add_div">
                                <div className="mx-auto">
                                    {funnelQuery.data.data.segment_list.map(x => x.id == event.data.value.api.funnelDetail ? <h6>{x.segment_name} Funnel is Setted</h6> : "")}
                                    <select className="form-select" onChange={(e) => FunnelSet(e.target.value)}>
                                        <option value="" disabled selected>Select Funnel</option>
                                        {funnelQuery.data.data.segment_list.map(x => <option value={x.id} selected={x.id == event.data.value.api.funnelDetail}>{x.segment_name}</option>)}
                                    </select>
                                    {funnelNumberCol(event.data.value.api)}
                                    <FunnelSch apiStrut={event.data.value.api} />
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="card-body mt-0 ">
                            <div className="contact_add_div">
                                <div className="mx-auto">
                                    <select className="form-select" onChange={(e) => FunnelSet(e.target.value)}>
                                        <option value="" disabled selected>Select Funnel</option>
                                        {funnelQuery.data.data.segment_list.map(x => <option value={x.id}>{x.segment_name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            return (
                <Fragment>
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <FunnelBox />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`} />
                </Fragment>
            )
        }

        else if (type == "save_funnel") {
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            {/* {funnelQuery.data.data.segment_list.map(x => x.id == event.data.value.api.funnelDetail ? <h6>{x.segment_name} Funnel is Setted</h6> : "")}
                             <MapColumns apiStrut={event.data.value} /> */}
                            <SaveFunnelBox setNodes={setNodes} apisData={event.data.value.api} id={id} eventData={event.data} funnelQuery={funnelQuery.data} />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="none" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`} />
                </Fragment>
            )
        }


        else if (type == "sms") {
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <SmsBox setNodes={setNodes} apisData={event.data.value.api} id={id} bsspSender={bsspSender} bsspTemp={bsspTemp} eventData={event.data} />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="normal" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`} />
                </Fragment>
            )
        }

        else if (type == "voice") {
            return (
                <Fragment>
                    <Handles type='target' parent={event.data.value.parent} />
                    <div className="main_flex_div">
                        <div className="card position-relative p-0 box_width h-lg-100">
                            <Header id={id} label={event.data.label} setShowConfirmation={setShowConfirmation} type={type} />
                            <VoiceBox setNodes={setNodes} apisData={event.data.value.api} id={id} bsspSender={bsspSender} bsspTemp={bsspTemp} eventData={event.data} />
                        </div>
                        <div className='start_btn_pos h-lg-100'>
                            <SideModal cond="all" id={`Sourcehandle-${id}`} event={event} items_ul2={items_ul2} typo="start_btn" modelType="normal" />
                        </div>
                    </div>
                    <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${id}`} />
                </Fragment>
            )
        }
    }

    const WADesign = ({ datas }) => {
        let searchTerm = /{{\d+}}/g;
        const [load, setLoad] = useState(false)
        if (datas.data.value.type == "whatsapp") {
            const item = datas.data.value.extra

            if (item && item != "") {
                if (datas.data.value.api.whatsapp_type == "interactive") {
                    const HeaderView = ({ headData }) => {
                        const { register: headerRes, handleSubmit: headerSub } = useForm({});
                        const [rerender, setRerender] = useState(false);
                        let matches = headData.match(searchTerm);
                        let vars = JSON.parse(datas.data.value.api.whatsapp_var) // Decoded
                        const varSet = (index, val) => {
                            let t = {}
                            t[`${index}`] = { "col": `col-${val}`, "type": "text" }
                            if (vars && vars != {}) {
                                if (vars.header && vars.header != {}) {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ ...vars, ...{ "header": { ...vars.header, ...t } } })
                                } else {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ ...vars, ...{ header: t } })
                                }
                            } else {
                                datas.data.value.api.whatsapp_var = JSON.stringify({ header: t })
                            }
                            setRerender(!rerender)
                        }

                        return (
                            <Fragment>
                                <p>{headData}</p>
                                {matches && matches.length > 0 ? (
                                    <Fragment>
                                        <label className="header_heading m-0">
                                            <i className="fa-solid fa-file-lines"></i> Header Variables
                                        </label>
                                        {
                                            matches.map((varValue, key) => (
                                                <select
                                                    value={vars.header && vars.header[`index${key}`] ? vars.header[`index${key}`]['col'].split("-")[1] : ""}
                                                    className="form-select form-select-sm" {...headerRes(`col_${varValue}`)}
                                                    onChange={(e) => varSet(`index${key}`, e.target.value)} key={key}
                                                >
                                                    <option value="" disabled>Select Variable {key + 1}</option>
                                                    <SelectOptions colObj={datas.data.value.columns} />
                                                </select>
                                            ))
                                        }
                                    </Fragment>
                                ) : (<p></p>)}
                            </Fragment>
                        );
                    }

                    const BodyView = ({ BodyData }) => {
                        const { register: bodyRes, control: bodyControl, handleSubmit: bodyHandle } = useForm({});
                        const [rerender, setRerender] = useState(false);
                        let matches = BodyData.match(searchTerm);
                        let vars = JSON.parse(datas.data.value.api.whatsapp_var) // Decoded

                        const varSet = (index, val) => {
                            let t = {}
                            t[`${index}`] = { "col": `col-${val}`, "type": "text" }
                            if (vars && vars != {}) {
                                if (vars.body && vars.body != {}) {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ ...vars, ...{ "body": { ...vars.body, ...t } } })
                                } else {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ ...vars, ...{ body: t } })
                                }
                            } else {
                                datas.data.value.api.whatsapp_var = JSON.stringify({ body: t })
                            }

                            setRerender(!rerender)
                        }

                        return (
                            <Fragment>
                                <p>{BodyData}</p>
                                {matches && matches.length > 0 ? (
                                    <Fragment>
                                        <label className="header_heading m-0">
                                            <i className="fa-solid fa-file-lines"></i> Body Variables
                                        </label>
                                        {
                                            matches.map((varValue, key) => (
                                                <Fragment key={key}>
                                                    <select
                                                        value={vars.body && vars.body[`index${key}`] ? vars.body[`index${key}`]['col'].split("-")[1] : ""}
                                                        className="form-select form-select-sm" {...bodyRes(`col_${varValue}`)}
                                                        onChange={(e) => varSet(`index${key}`, e.target.value)}
                                                    >
                                                        <option value="" disabled>Select Variable {key + 1}</option>
                                                        <SelectOptions colObj={datas.data.value.columns} />
                                                    </select>
                                                </Fragment>
                                            ))
                                        }
                                    </Fragment>
                                ) : (<p></p>)}
                            </Fragment>
                        );

                    }

                    const header_fun = (i) => {
                        if (i.header_data != "" && i.header_data != null) {
                            if (i.header_type == "text") {
                                return (
                                    <div className="header_teml">
                                        <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                        <div className="header_enter_text_box">
                                            <HeaderView headData={i.header_data} />
                                        </div>
                                    </div>
                                )
                            } else if (i.header_type == "image") {
                                return (
                                    <div className="header_teml">
                                        <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                        <div className="header_enter_text_box">
                                            <div className="img-fluid">  <img src={i.header_data} alt="WA Header Image" /> </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    }

                    const footer_fun = (i) => {
                        if (i.footer != "" && i.footer != null) {
                            return (
                                <div className="footer_teml mt-2">
                                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                                    <div className="footer_enter_text_box">
                                        <p>{i.footer}</p>
                                    </div>
                                </div>
                            )
                        }
                    }

                    const ButtonFunc = ({ i }) => {
                        const main_arr = JSON.parse(datas.data.value.api.interactive_btn)
                        if (!main_arr || main_arr == null) {
                            console.log(datas.data.value)
                            return;
                        }
                        if (datas.data.value.api.interactive_type == "list") {

                            const groupedBySection = main_arr.reduce((acc, item) => {
                                if (acc[item.section_no]) {
                                    acc[item.section_no].rows.push(item);
                                } else {
                                    acc[item.section_no] = {
                                        section_no: item.section_no,
                                        section_title: item.section_title,
                                        rows: [item]
                                    };
                                }
                                return acc;
                            }, {});

                            const resultArray = useMemo(() => Object.values(groupedBySection), [groupedBySection]);

                            const RowDetails = ({ newDatas }) => {
                                return (
                                    <Fragment>
                                        {
                                            newDatas.map((x, sec_ind) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className='flex mb-2'>
                                                                <div className="Button_div_box ">
                                                                    <p> {x.row_title}</p>
                                                                </div>
                                                                <div className="downBtnPlus">
                                                                    <SideModal
                                                                        cond={x.row_title + " List(Button)"}
                                                                        id={`list_btn-${x.row_title}-${sec_ind}`}
                                                                        event={datas}
                                                                        items_ul2={items_ul2}
                                                                        typo="start_btn_new"
                                                                        modelType="none"
                                                                    />
                                                                    <Handle
                                                                        type="source" position="right" id={`list_btn-${x.row_title}-${sec_ind}`} className="right_main main_point"
                                                                        onConnect={(params) => onConnect({ ...params, "cond": x.row_title + " List(Button)", datas: datas })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Fragment>
                                )
                            }

                            return (
                                <Fragment>
                                    {
                                        resultArray.map((i) => {
                                            return (
                                                <Fragment>
                                                    <div className="d-flex justify-content-center">
                                                        <h6>{i.section_title}</h6>
                                                    </div>
                                                    <RowDetails newDatas={i.rows} />
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        }
                        else if (datas.data.value.api.interactive_type == "button") {
                            return main_arr.map((x, sec_ind) => {
                                return (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className='flex mb-2'>
                                                <div className="Button_div_box ">
                                                    <p> {x.title}</p>
                                                </div>
                                                <div className="downBtnPlus">
                                                    <SideModal cond={x.title + " Button"} id={`button_btn-${x.title}-${sec_ind}`} event={datas} items_ul2={items_ul2} typo="start_btn_new" modelType="none" />
                                                    <Handle
                                                        type="source" position="right" className="right_main main_point" id={`button_btn-${x.title}-${sec_ind}`}
                                                        onConnect={(params) => onConnect({ ...params, "cond": x.title + " Button", "datas": datas })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    }

                    const editingFun = (e, typo) => {
                        setNodes(nds => nds.map(node => {
                            if (node.id == datas.id) {
                                node.data.value.type = "edit_whatsapp"
                                node.data.value.api.ndsType = "edit"
                            }
                            return node;
                        }))
                        setLoad(!load)
                    }
                    return (
                        <Fragment>
                            <Handles type='target' parent={datas.data.value.parent} />
                            <div className="main_flex_div">
                                <div className="card position-relative p-0 box_width h-lg-100">
                                    <div className="card-header temp_bg">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <h5 className="mt-2 font-weight-bold temp_text_color a4">
                                                    <i className="fa-solid fa-photo-film"> </i> {datas.data.label}
                                                </h5>
                                            </div>
                                            <div className="col-md-5 text-right">

                                                <button type="button" className="btn-sm btn-success" onClick={(e) => editingFun(e, "edit")}>
                                                    <i className="fa-solid fa-user-pen"></i>
                                                </button>


                                                <button type="button" className="btn-sm btn-danger" onClick={(e) => setShowConfirmation([true, datas.id])}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body mt-0 ">
                                        {header_fun(item)}
                                        <div className="body_teml mt-1 mb-1">
                                            <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                                            <div className="body_enter_text_box">
                                                <BodyView BodyData={item.body} />
                                            </div>
                                        </div>
                                        {footer_fun(item)}
                                        <div className="line_ashish"> </div>
                                        <div className="Button_teml">
                                            <ButtonFunc i={item} />
                                        </div>
                                    </div>
                                </div>
                                <div className='start_btn_pos h-lg-100'>
                                    <SideModal cond={"wa_reply"} id={`Sourcehandle-${datas.id}`} event={datas} items_ul2={items_ul2} typo="start_btn" modelType="normal" />
                                </div>
                            </div>
                            <Handle type="source" position="right" className="main_point" id={`Sourcehandle-${datas.id}`} />
                        </Fragment>
                    )
                } else if (datas.data.value.api.whatsapp_type == "template") {

                    const HeaderView = ({ vals }) => {
                        const { register: headerRes, handleSubmit: headerSub } = useForm({});
                        let vars = JSON.parse(datas.data.value.api.whatsapp_var) // Decoded

                        const varSetHeader = (index, val, type) => {
                            let t = {}
                            t[`${index}`] = { "col": `col-${val}`, "type": type }

                            if (vars && vars != {}) {
                                if (vars.header && vars.header != {}) {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ "header": { ...vars.header, ...t } })
                                } else {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ ...vars, ...{ header: t } })
                                }
                            } else {
                                datas.data.value.api.whatsapp_var = JSON.stringify({ header: t })
                            }
                        }

                        const HeaderFun = () => {
                            if (vals.example && vals.example != {}) {
                                if (vals.example.header_text && vals.example.header_text.length != 0) {
                                    return (
                                        <Fragment>
                                            <label className="header_heading m-0">
                                                <i className="fa-solid fa-file-lines"></i> Header Variables
                                            </label>
                                            {
                                                vals.example.header_text.map((varValue, key) => (
                                                    <select
                                                        value={vars.header && vars.header[`index${key}`] ? vars.header[`index${key}`]['col'].split("-")[1] : ""}
                                                        className="form-select form-select-sm" {...headerRes(`col_${varValue}`)} key={key}
                                                        onChange={(e) => varSetHeader(`index${key}`, e.target.value, "text")}
                                                    >
                                                        <option value="" disabled>Select Variable {key + 1}</option>
                                                        <SelectOptions colObj={datas.data.value.columns} />
                                                    </select>
                                                ))
                                            }
                                        </Fragment>
                                    )
                                }
                                else if (vals.example.header_handle && vals.example.header_handle.length != 0) {
                                    return (
                                        <Fragment>
                                            <label className="header_heading m-0">
                                                <i className="fa-solid fa-file-lines"></i> Header Variables
                                            </label>
                                            {
                                                vals.example.header_handle.map((varValue, key) => (
                                                    <select
                                                        value={vars.header && vars.header[`index${key}`] ? vars.header[`index${key}`]['col'].split("-")[1] : ""}
                                                        className="form-select form-select-sm" {...headerRes(`col_${varValue}`)} key={key}
                                                        onChange={(e) => varSetHeader(`index${key}`, e.target.value, vals.format)}
                                                    >
                                                        <option value="" disabled>Select {vals.format} File</option>
                                                        <SelectOptions colObj={datas.data.value.columns} />
                                                    </select>

                                                ))
                                            }
                                        </Fragment>
                                    )
                                }
                            }
                        }
                        return <HeaderFun />
                    }

                    const BodyView = ({ vals }) => {
                        const { register: bodyRes, handleSubmit: headerSub } = useForm({});
                        let vars = JSON.parse(datas.data.value.api.whatsapp_var) // Decoded

                        const varSetBody = (index, val, type) => {
                            let t = {}
                            t[`${index}`] = { "col": `col-${val}`, "type": type }

                            if (vars && vars != {}) {
                                if (vars.body && vars.body != {}) {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ "body": { ...vars.header, ...t } })
                                } else {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ ...vars, ...{ body: t } })
                                }
                            } else {
                                datas.data.value.api.whatsapp_var = JSON.stringify({ body: t })
                            }
                        }


                        if (vals.example && vals.example != {}) {
                            if (vals.example.body_text && vals.example.body_text.length != 0) {
                                return (
                                    <Fragment>
                                        <label className="header_heading m-0">
                                            <i className="fa-solid fa-file-lines"></i> Body Variables
                                        </label>
                                        {
                                            vals.example.body_text[0].map((varValue, key) => (
                                                <Fragment key={key}>
                                                    <select
                                                        value={vars.body && vars.body[`index${key}`] ? vars.body[`index${key}`]['col'].split("-")[1] : ""}
                                                        className="form-select form-select-sm" {...bodyRes(`col_${varValue}`)}
                                                        onChange={(e) => varSetBody(`index${key}`, e.target.value, "text")}
                                                    >
                                                        <option value="" disabled>Select Variable {key + 1}</option>
                                                        <SelectOptions colObj={datas.data.value.columns} />
                                                    </select>
                                                </Fragment>
                                            ))
                                        }
                                    </Fragment>
                                )
                            }
                        }
                    }

                    const BtnView = ({ vals }) => {
                        const { register: btnRes, handleSubmit: headerSub } = useForm({});
                        let vars = JSON.parse(datas.data.value.api.whatsapp_var) // Decoded
                        const varSetBtn = (index, val, type) => {
                            let t = {}
                            t[`${index}`] = { "col": `col-${val}`, "type": type }
                            if (vars && vars != {}) {
                                if (vars.button && vars.button != {}) {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ "button": { ...vars.button, ...t } })
                                } else {
                                    datas.data.value.api.whatsapp_var = JSON.stringify({ ...vars, ...{ button: t } })
                                }
                            } else {
                                datas.data.value.api.whatsapp_var = JSON.stringify({ button: t })
                            }
                        }

                        const BtnFun = () => {
                            if (vals.type == "BUTTONS") {
                                return vals.buttons.map((btn_type, newKey) => {
                                    if (btn_type.type == "URL" && btn_type.example && btn_type.example.length != 0) {
                                        return btn_type.example.map((varValue) => {
                                            return (
                                                <Fragment key={newKey}>
                                                    <select
                                                        value={vars.button && vars.button[`index${newKey}`] ? vars.button[`index${newKey}`]['col'].split("-")[1] : ""}
                                                        className="form-select form-select-sm" {...btnRes(`col_${btn_type.text}`)}
                                                        onChange={(e) => varSetBtn(`index${newKey}`, e.target.value, btn_type.type)}
                                                    >
                                                        <option value="" disabled>Select Variable {newKey + 1}</option>
                                                        <SelectOptions colObj={datas.data.value.columns} />
                                                    </select>
                                                </Fragment>
                                            )
                                        })
                                    }
                                })
                            }
                        }
                        return (
                            <Fragment>
                                <label className="header_heading m-0">
                                    <i className="fa-solid fa-file-lines"></i> Button Variables
                                </label>
                                <BtnFun />
                            </Fragment>
                        );
                    }

                    const temp_button_fun = (vals) => {
                        if (vals.length == 0) {
                            return ""
                        }
                        return (
                            <Fragment>
                                <div className="line_ashish"> </div>
                                <div className="Button_teml">
                                    {vals.map((x, sec_ind) => {
                                        if (x.type == "QUICK_REPLY") {
                                            return (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className='flex mb-2'>
                                                            <div className="Button_div_box ">
                                                                <p> {x.text} </p>
                                                            </div>
                                                            <div className="downBtnPlus">
                                                                <SideModal cond={x.text + " Button"} id={`temp_btn-${x.text}-${sec_ind}`} event={datas} items_ul2={items_ul2} typo="start_btn_new" modelType="none" />
                                                                <Handle
                                                                    type="source" position="right" className="right_main main_point" id={`temp_btn-${x.text}-${sec_ind}`}
                                                                    onConnect={(params) => onConnect({ ...params, "cond": x.text + " Button", "datas": datas })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </Fragment>
                        )
                    }

                    const new_function = () => {
                        return JSON.parse(item.companents).map(value => {
                            if (value.type == "HEADER") {
                                if (value.format == "TEXT") {
                                    return (
                                        <Fragment>
                                            <div className="header_teml">
                                                <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                                <div className="header_enter_text_box">
                                                    <p> {value.text}</p>
                                                    <HeaderView vals={value} />
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                } else if (value.format == "IMAGE") {
                                    return (
                                        <div className="header_teml">
                                            <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                                            <div className="header_enter_text_box">
                                                <div className="img-fluid">   <img src={value.example.header_handle[0]} /> </div>
                                                <HeaderView vals={value} />
                                            </div>
                                        </div>
                                    )
                                } else if (value.format == "DOCUMENT") { }
                            } else if (value.type == "BODY") {
                                return (
                                    <div className="body_teml mt-1 mb-1">
                                        <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                                        <div className="body_enter_text_box">
                                            <p>{value.text}</p>
                                            <BodyView vals={value} />
                                        </div>
                                    </div>
                                )
                            } else if (value.type == "BUTTONS") {
                                return (
                                    <div>
                                        {temp_button_fun(value.buttons)}
                                        <BtnView vals={value} />
                                    </div>
                                )
                            }
                        })
                    }

                    return (
                        <Fragment>
                            <Handles type='target' parent={datas.data.value.parent} />
                            <div className="main_flex_div">
                                <div className="card position-relative p-0 box_width h-lg-100">
                                    <div className="card-header temp_bg">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <h5 className="mt-2 font-weight-bold temp_text_color a1">
                                                    <i className="fa-regular fa-images"></i> {datas.data.label}
                                                </h5>
                                            </div>
                                            <div className="col-md-5 text-right">
                                                <button type="button" className="btn-sm btn-danger" onClick={(e) => setShowConfirmation([true, datas.id])}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body mt-0">{new_function()}</div>
                                </div>

                                <div className='start_btn_pos h-lg-100'>
                                    <SideModal cond={"wa_reply"} id={`Sourcehandle-${datas.id}`} event={datas} items_ul2={items_ul2} typo="start_btn" modelType="normal" />
                                </div>
                            </div>
                            <Handle
                                type="source" position="right" className="main_point" id={`Sourcehandle-${datas.id}`}
                                onConnect={(params) => onConnect({ ...params, "cond": "wa_reply", datas: datas })}
                            />
                        </Fragment>
                    )
                }
            }
        } else if (datas.data.value.type == "pre_whatsapp") {
            return <WAPreDesign datas={datas} setLoad={setLoad} load={load} />
        }
        else if (datas.data.value.type == "edit_whatsapp") {
            return <WAEditDesign datas={datas} setLoad={setLoad} load={load} />
        }
    }

    const WAEditDesign = ({ datas, setLoad, load }) => {
        const int_btn = datas.data.value.api.interactive_btn
        const showError = (msg) => {
            toast.error(msg);
        }
        if (datas.data.value.api.whatsapp_type == "interactive") {
            if (datas.data.value.api.interactive_type == "button") {
                return (
                    <Fragment>
                        <Handles type='target' parent={datas.data.value.parent} />
                        <div className="card position-relative p-0 box_width">
                            <div className="card-header temp_bg">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h5 className="mt-2 font-weight-bold temp_text_color a2">
                                            <i className="fa-regular fa-address-card"></i> {datas.data.label}
                                        </h5>
                                    </div>
                                    <div className="col-md-5 text-right">
                                        <button onClick={(e) => setShowConfirmation([true, datas.id])} type="button" className="btn-sm btn-danger">
                                            <i className="fa-solid fa-trash"></i>
                                        </button>


                                        <button type="button" className="btn-sm btn-success" onClick={(e) => setWaPreview([true, "button", datas.id])}>
                                            <i className="fa-solid fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body mt-0">
                                <EditButtonTemp
                                    setLoad={setLoad}
                                    load={load}
                                    nodeId={datas.id}
                                    datas={datas.data.value}
                                    showError={showError}
                                    setNodes={setNodes} />
                            </div>
                        </div>
                    </Fragment>
                )
            }

            else if (datas.data.value.api.interactive_type == "list") {
                return (
                    <Fragment>
                        <Handles type='target' parent={datas.data.value.parent} />
                        <div className="card position-relative p-0 box_width">
                            <div className="card-header temp_bg">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h5 className="mt-2 font-weight-bold temp_text_color a3">
                                            <i className="fa-regular fa-rectangle-list"></i> {datas.data.label}
                                        </h5>
                                    </div>
                                    <div className="col-md-5 text-right">
                                        <button onClick={(e) => setShowConfirmation([true, datas.id])} type="button" className="btn-sm btn-danger">
                                            <i className="fa-solid fa-trash"></i>
                                        </button>
                                        <button type="button" className="btn-sm btn-success" onClick={(e) => setWaPreview([true, "button", datas.id])}>
                                            <i className="fa-solid fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body mt-0">
                                <EditListTemp
                                    setNodes={setNodes}
                                    setEdges={setEdges}
                                    setLoad={setLoad}
                                    load={load}
                                    nodeId={datas.id}
                                    showError={showError}
                                    datas={datas.data.value}
                                />
                            </div>
                        </div>
                    </Fragment>
                )
            }

        }
    }

    const WAPreDesign = ({ datas, setLoad, load }) => {
        const saveFormData = (formData) => {
            setNodes(nds => nds.map(node => {
                if (node.id == formData.nodeId) {
                    const nodeVal = node.data.value
                    node.data.label = formData.template_name
                    nodeVal.type = "whatsapp"
                    let extras = {
                        "template_id": "",
                        "title": formData.template_name,
                        "int_type": "button",
                        "header_type": "text",
                        "header_data": formData.header,
                        "file_name": null,
                        "body": formData.body,
                        "footer": formData.footer,
                        "is_variable": "0"
                    }
                    nodeVal.api.interactive_btn = JSON.stringify(formData.button)
                    nodeVal.extra = extras
                }
                return node;
            }))
            setLoad(!load)
            setRefecther(!refecther)
        };

        const editBtnData = (formData) => {
            console.log(formData)
        }

        const saveListFormData = (formData) => {
            let listArr = [];
            formData.action.map((item, index) => {
                item.rows.map((rowEle, rowInd) => {
                    listArr.push({
                        "button_title": formData.btn_title,
                        "section_no": index + 1,
                        "section_title": item.title,
                        "row_no": rowInd + 1,
                        "row_id": "65d6e562d9bd9",
                        "row_title": rowEle.row_title,
                        "row_desc": rowEle.row_description
                    })
                })
            })
            setNodes(nds => nds.map(node => {
                if (node.id == formData.nodeId) {
                    const nodeVal = node.data.value
                    node.data.label = formData.template_name
                    nodeVal.type = "whatsapp"
                    let extras = {
                        "template_id": "",
                        "title": formData.template_name,
                        "int_type": "list",
                        "header_type": "text",
                        "header_data": formData.header,
                        "file_name": null,
                        "body": formData.body,
                        "footer": formData.footer,
                        "is_variable": "0"
                    }
                    nodeVal.api.interactive_btn = JSON.stringify(listArr)
                    nodeVal.api.intListData = JSON.stringify(formData.action)
                    nodeVal.api.intListTitle = formData.btn_title
                    nodeVal.extra = extras
                }
                return node;
            }))
            setLoad(!load)
            setRefecther(!refecther)
        }

        const showError = (msg) => {
            toast.error(msg);
        }


        if (datas.data.value.api.whatsapp_type == "interactive") {
            if (datas.data.value.api.interactive_type == "button") {
                if (datas.data.value.api.ndsType == "edit") {
                    return (
                        <Fragment>
                            <Handles type='target' parent={datas.data.value.parent} />
                            <div className="card position-relative p-0 box_width">
                                <div className="card-header temp_bg">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h5 className="mt-2 font-weight-bold temp_text_color a2">
                                                <i className="fa-regular fa-address-card"></i> {datas.data.label}
                                            </h5>
                                        </div>
                                        <div className="col-md-5 text-right">
                                            <button type="button" className="btn-sm btn-danger" onClick={(e) => setShowConfirmation([true, datas.id])}>
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body mt-0">
                                    <AddNewButtonTemp
                                        setLoad={setLoad}
                                        load={load}
                                        onSave={editBtnData}
                                        nodeId={datas.id}
                                        datas={datas.data.value}
                                        showError={showError}
                                        setNodes={setNodes} />
                                </div>
                            </div>
                        </Fragment>
                    )
                } else if (datas.data.value.api.ndsType == "new") {
                    return (
                        <Fragment>
                            <Handles type='target' parent={datas.data.value.parent} />
                            <div className="card position-relative p-0 box_width">
                                <div className="card-header temp_bg">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h5 className="mt-2 font-weight-bold temp_text_color a2">
                                                <i className="fa-regular fa-address-card"></i> {datas.data.label}
                                            </h5>
                                        </div>
                                        <div className="col-md-5 text-right">
                                            <button type="button" className="btn-sm btn-danger" onClick={(e) => setShowConfirmation([true, datas.id])}>
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                            <button type="button" className="btn-sm btn-success" onClick={(e) => setWaPreview([true, "button", datas.id])}>
                                                <i className="fa-solid fa-eye"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body mt-0">
                                    <AddNewButtonTemp
                                        setLoad={setLoad}
                                        load={load}
                                        onSave={saveFormData}
                                        nodeId={datas.id}
                                        datas={datas.data.value}
                                        showError={showError}
                                        setNodes={setNodes} />
                                </div>
                            </div>
                        </Fragment>
                    )
                }

            } else if (datas.data.value.api.interactive_type == "list") {
                return (
                    <Fragment>
                        <Handles type='target' parent={datas.data.value.parent} />
                        <div className="card position-relative p-0 box_width">
                            <div className="card-header temp_bg">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h5 className="mt-2 font-weight-bold temp_text_color a3">
                                            <i className="fa-regular fa-rectangle-list"></i> {datas.data.label}
                                        </h5>
                                    </div>
                                    <div className="col-md-5 text-right">
                                        <button
                                            onClick={(e) => setShowConfirmation([true, datas.id])}
                                            type="button" className="btn-sm btn-danger"
                                        ><i className="fa-solid fa-trash"></i> </button>

                                        <button type="button" className="btn-sm btn-success" onClick={(e) => setWaPreview([true, "list", datas.id])}>
                                            <i className="fa-solid fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body mt-0">
                                <AddNewListTemp
                                    setNodes={setNodes}
                                    setLoad={setLoad}
                                    load={load}
                                    onSave={saveListFormData}
                                    nodeId={datas.id}
                                    showError={showError}
                                    datas={datas.data.value} />
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    }




    const CustomEdge = (event) => {
        let sourceX = event.sourceX
        let sourceY = event.sourceY
        let sourcePosition = event.sourcePosition
        let targetX = event.targetX
        let targetY = event.targetY
        let targetPosition = event.targetPosition


        let parentDetails = event.data.parent

        const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });

        const condSaveFun = (formDatas) => {
            setEdges(nds => {
                return nds.map(i => {
                    if (i.id == event.id) {
                        i.data.extra_cond = formDatas
                    }
                    return i
                })
            })
        }

        const WaitTillBox = () => {
            const newRef = useRef();
            const [dateType, setDateType] = useState(event.data.schedule.dateType);
            let dt = ""
            if (event.data.schedule.date && event.data.schedule.date != "") {
                const newDt = event.data.schedule.date.split("-")
                dt = new Date(newDt[0], newDt[1], newDt[2], event.data.schedule.hours)
            } else {
                dt = new Date()
            }
            const { register: wait_reg, control: wait_control, handleSubmit: wait_handle } = useForm({
                defaultValues: {
                    "schType": "wait_till",
                    "dateType": event.data.schedule.dateType,
                    "days": event.data.schedule.days,
                    "hours": event.data.schedule.hours,
                    "mins": event.data.schedule.mins,
                    "date": dt
                }
            });


            const wait_set = (val, typo) => {
                setEdges(nds => {
                    return nds.map(i => {
                        if (i.id == event.id) {
                            i.data.schedule.schType = "wait_till"
                            if (typo == "mins") {
                                i.data.schedule.mins = val
                            } else if (typo == "hours") {
                                i.data.schedule.hours = val
                            } else if (typo == "days") {
                                i.data.schedule.days = val
                            } else if (typo == "date") {
                                const dateObj = new Date(val);
                                const year = dateObj.getFullYear();
                                const month = String(dateObj.getMonth()).padStart(2, '0');
                                const day = String(dateObj.getDate()).padStart(2, '0');
                                const formattedDate = `${year}-${month}-${day}`;
                                i.data.schedule.date = formattedDate
                                i.data.schedule.hours = String(dateObj.getHours()).padStart(2, '0')
                            } else if (typo == "dateType") {
                                i.data.schedule.dateType = val
                                setDateType(val)
                            }
                        }
                        return i
                    })
                })
            }

            const NewBox = () => {
                if (dateType != "") {
                    if (dateType == "normal") {
                        return (
                            <div className="flex justify-around  bg-white_green">
                                <div className="flex flex-col">
                                    <select {...wait_reg("days", { required: true, onChange: (e) => wait_set(e.target.value, "days") })} className='form-select'>
                                        {Array.from(Array(31).keys()).map(item => <option value={item}>{item}</option>)}
                                    </select>
                                    <span className="text-sm font-semibold text-slate-500">After Days </span>
                                </div>
                                <div className="flex flex-col">
                                    <select {...wait_reg("hours", { required: true, onChange: (e) => wait_set(e.target.value, "hours") })} className='form-select'>
                                        {Array.from(Array(24).keys()).map(item => <option value={item}>{item}</option>)}
                                    </select>
                                    <span className="text-sm font-semibold text-slate-500">After HOURS </span>
                                </div>

                                {/* <div className="flex flex-col">
                                    <select {...wait_reg("mins", { required: true, onChange: (e) => wait_set(e.target.value, "mins") })} className='form-select'>
                                        {Array.from(Array(60).keys()).map(item => <option value={item}>{item}</option>)}
                                    </select>
                                    <span className="text-sm font-semibold text-slate-500">After Mins </span>
                                </div> */}
                            </div>
                        )
                    }
                    else if (dateType == "date") {
                        return (
                            <div>
                                <Controller
                                    name={'date'}
                                    control={wait_control}
                                    value={dt}
                                    render={({ field }) => {
                                        return (<DatePicker
                                            className="form-control"
                                            selected={field.value}
                                            dateFormat="MMMM d, yyyy HH:mm"
                                            showTimeSelect
                                            timeFormat="HH"
                                            timeIntervals={60}
                                            minDate={new Date()}
                                            onChange={(date) => {
                                                field.onChange(date)
                                                wait_set(date, "date");
                                            }}
                                        />
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                            </div>
                        )
                    }
                }
            }

            return (
                <div className="card-body mt-0 ">
                    <div className="contact_add_div">
                        <div className="mx-auto" ref={newRef}>
                            <input {...wait_reg("schType", { required: true })} type="hidden" value='wait_till' />
                            <select {...wait_reg("dateType", { required: true, onChange: (e) => wait_set(e.target.value, 'dateType') })} className='form-select color_date_bg'>
                                <option value="">Select Date Type</option>
                                <option value="normal">Normal</option>
                                <option value="date">Date</option>
                            </select>
                            <NewBox />
                        </div>
                    </div>
                </div>
            )
        }


        const BeforeBox = () => {

            const [delayType, setDelayType] = useState(event.data.schedule.delay_type);
            const [dateType, setDateType] = useState(event.data.schedule.day_type);



            let dt = new Date()


            if (event.data.schedule.delay_date && event.data.schedule.delay_date != "") {
                const newDt = event.data.schedule.delay_date.split("-")
                console.log(newDt[0], newDt[1], newDt[2])
                dt = new Date(newDt[0], newDt[1] - 1, newDt[2], event.data.schedule.delay_hours)



            }






            const newRef = useRef();

            const { register: wait_reg, control: wait_control, handleSubmit: wait_handle } = useForm({
                defaultValues: {
                    "delay_type": event.data.schedule.delay_type,
                    "day_type": event.data.schedule.day_type,
                    "delay_hours": event.data.schedule.delay_hours,
                    "delay_date": dt,
                }
            }

            );
            // console.log(event.data.schedule)
            const wait_set = (val, typo) => {
                setEdges(nds => {
                    return nds.map(i => {
                        if (i.id == event.id) {
                            if (typo == "delayType") {
                                i.data.schedule.delay_type = val
                                setDelayType(val)
                            } else if (typo == "dateType") {
                                i.data.schedule.day_type = val
                                setDateType(val)
                            } else if (typo == "date") {
                                const dateObj = new Date(val);
                                const year = dateObj.getFullYear();
                                const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                                const day = String(dateObj.getDate()).padStart(2, '0');
                                const formattedDate = `${year}-${month}-${day}`;
                                console.log(formattedDate)
                                i.data.schedule.delay_date = formattedDate
                                i.data.schedule.hours = String(dateObj.getHours()).padStart(2, '0')
                            } else if (typo == "time") {
                                i.data.schedule.delay_hours = val
                                console.log(val)
                            }
                        }
                        return i
                    })
                })
            }
            const NewBox = () => {
                if (dateType != "") {
                    if (dateType == "date") {
                        return (
                            <div>
                                <Controller
                                    name={'delay_date'}
                                    control={wait_control}
                                    value={dt}
                                    render={({ field }) => {
                                        return (<DatePicker
                                            className="form-control"
                                            selected={field.value}
                                            dateFormat="MMMM d, yyyy HH:mm"
                                            showTimeSelect
                                            timeFormat="HH"
                                            timeIntervals={60}
                                            minDate={new Date()}
                                            onChange={(date) => {
                                                field.onChange(date)
                                                wait_set(date, "date");
                                            }}
                                        />
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                            </div>
                        )
                    }

                    else if (dateType == "today") {
                        return (
                            <div>
                                <select {...wait_reg("delay_hours", { required: true, onChange: (e) => wait_set(e.target.value, "time") })} className='form-select'>
                                    {Array.from(Array(24).keys()).map(item => <option value={item}>{item}</option>)}
                                </select>
                                <span className="text-sm font-semibold text-slate-500">After HOURS </span>
                            </div>
                        )
                    }
                }

            }

            const DateBox = () => {
                if (delayType != "" && delayType != null) {
                    console.log(delayType)
                    return (
                        <select {...wait_reg("day_type", { required: true, onChange: (e) => wait_set(e.target.value, 'dateType') })} className='form-select color_date_bg'>
                            <option value="">Select Date Type</option>
                            <option value="date">Date</option>
                            <option value="today">Today</option>
                        </select>
                    )
                }
            }

            return (
                <div className="card-body mt-0 ">
                    <div className="contact_add_div">
                        <div className="mx-auto" ref={newRef}>
                            <select {...wait_reg("delay_type", { required: true, onChange: (e) => wait_set(e.target.value, 'delayType') })} className='form-select color_date_bg'>
                                <option value="">Select Delay Type</option>
                                <option value="after">After</option>
                                <option value="before">Before</option>
                            </select>
                            <DateBox />
                            <NewBox />
                        </div>
                    </div>
                </div>
            )
        }


        const MiddleBox = () => {
            const [boxStatus, setBoxStatus] = useState(false)
            if (boxStatus == false) {
                return (
                    <div className="edge_button">
                        <button className="btn btn-sm text-whait" type='button' onClick={() => setBoxStatus(true)}>
                            <i className="fa-solid fa-plus"></i>
                        </button>
                    </div>
                )
            } else {
                return (
                    <div className="flex">
                        <div className="con_ashish">
                            <CondComponet2 allDatas={event} parent={parentDetails} condSave={condSaveFun} />
                        </div>
                        <div className="sedu_ashish">
                            <WaitTillBox />

                        </div>
                    </div>
                )
            }
        }

        return (
            <Fragment>
                <BaseEdge path={edgePath} label={event.data.label} />
                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            fontSize: 12,
                            pointerEvents: 'all',
                        }}
                    >
                        {/* <p>{event.data.label}</p> */}
                        <MiddleBox />

                        <BeforeBox />
                        {/* <div className="flex">
                            <div className="con_ashish">
                                <CondComponet2 allDatas={event} parent={parentDetails} condSave={condSaveFun} />
                            </div>
                            <div className="sedu_ashish">
                                <WaitTillBox />
                            </div>
                        </div> */}
                    </div>
                </EdgeLabelRenderer>
            </Fragment>
        );
    }

    const nodeTypes = useMemo(() => ({ customnode: CustomNode }), [waInterQuery.data, waTempQuery.data, webhookQuery.data, funnelQuery.data])
    const edgeTypes = useMemo(() => ({ customedge: CustomEdge }), []);



    const onSave = useCallback((formDatas) => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            let flow_obj
            if (id) {
                flow_obj = {
                    flow: flow,
                    flow_name: formDatas.name,
                    phone_no_id: formDatas.phone_no_id,
                    flow_id: id,
                }
            } else {
                flow_obj = {
                    flow: flow,
                    flow_name: formDatas.name,
                    phone_no_id: formDatas.phone_no_id
                }
            }
            try {
                axios.post(host + "/new_save_flow", { flow_data: flow_obj }, headers).then(response => {
                    console.log(response.data)
                    toast.success(response.data.message)
                    setTimeout(() => {
                        window.location.href = `/new/${response.data.flow_id}`
                    }, 3000);
                })
            } catch (error) {
                console.log(error)
                throw new Error('Failed to fetch day from API');
            }

            console.log(flow)
        }
    }, [rfInstance]);



    const onConnect = useCallback((allparams) => {
        if (allparams.cond && allparams.datas) {
            const newEdge = {
                'source': allparams.source,
                'target': allparams.target,
                'sourceHandle': allparams.sourceHandle,
                'targetHandle': null,
                'id': `reactflow__edge-${allparams.source}-${allparams.target}-${Math.floor(Math.random() * 9999)}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "label": allparams.cond,
                    "parent": allparams.datas,
                    "custom_condition": "",
                    "schedule": {
                        "schType": '', 'days': "0", "dateType": "", "hours": '0', "mins": '0',
                        "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "extra": { 'srcHandle': allparams.sourceHandle, 'trgHandle': null, },
                    "extra_cond": {}
                }
            }

            return setEdges((eds) => {
                eds.push(newEdge)
                return eds.map(i => {
                    // console.log(i)
                    return i
                })
            });
        }
    }, []);


    const onPreview = () => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            return (
                <div className="confirmation-popup">
                    <div className="modal_as show-modal_as">
                        <div className="modal-content_as_side_pop p-0">
                            <div className="prv_scrl">
                                <div className=" ">
                                    <div className="flow_ashish_data"> <PreviewComp flow={flow} /> </div>
                                    <div className="btn_full_ashish mt-2"> <button className="btn-sm btn-danger" onClick={() => setFlowPreview(false)}> Close </button> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    };


    const addChannels = (channelVals) => {
        const parent_node = channelVals[2]
        const parent = channelVals[2].id
        const src_handle = channelVals[4]
        const cond = channelVals[3]
        const node_id = channelVals[1] + `-channel-${+Date.now()}`


        const apiDetail = parent_node.data.value.api
        const type = parent_node.data.value.type


        if (type == "on_message") {
            if (Object.keys(apiDetail.keywords).length == 0) {
                toast.error("Add atleast one Keyword in On Message Trigger")
                return;
            }
        } else if (type == "on_webhook") {
            if (apiDetail.webhookDetail == "" || apiDetail.phone_number_col == "") {
                toast.error("Add Webhook Detail")
                return;
            }
        } else if (type == "on_funnel") {
            if (apiDetail.funnelDetail == "" || apiDetail.phone_number_col == "") {
                toast.error("Add Funnel Detail first")
                return;
            }
        } else if (type == "on_attribute") {
            if (apiDetail.attributeDetails == "") {
                toast.error("Add Attribute Detail first")
                return;
            }
        }



        if (channelVals[1] == "button") {
            try {
                axios.post("https://tickmaker.io/api/get-int-template-by-id", {
                    "auth_key": wa_authKey,
                    "template_id": channelVals[0].template_id,
                    "interactive_type": "button",
                }, headers).then(resp => {
                    if (resp.data.status == "success") {
                        let api = {
                            'campaign_name': channelVals[0].title,
                            'sender': "dgffgh",
                            'whatsapp_var': JSON.stringify({}),
                            'template_id': channelVals[0].template_id,
                            "auth_key": wa_authKey,
                            "whatsapp_type": "interactive",
                            "interactive_type": "button",
                            "interactive_btn": JSON.stringify(resp.data.data)
                        }
                        const node1 = {
                            'id': node_id,
                            'type': 'customnode',
                            'position': getPos(parent_node),
                            'data': {
                                'label': channelVals[0].title,
                                'value': {
                                    'parent': parent,
                                    'type': "whatsapp",
                                    'api': api,
                                    'description': "",
                                    "extra": channelVals[0],
                                    "columns": parent_node.data.value.columns
                                }
                            }
                        }
                        const edge1 = {
                            'source': parent,
                            'target': node_id,
                            'sourceHandle': src_handle,
                            'targetHandle': null,
                            'id': `reactflow__edge-${parent}-${node_id}`,
                            'animated': false,
                            'type': "customedge",
                            'data': {
                                "delay": { "days": 0, "hours": 0, "minutes": 0 },
                                "label": cond,
                                "parent": parent_node,
                                "custom_condition": "",
                                "schedule": {
                                    "schType": '', 'days': "0", "dateType": "", "hours": '0', "mins": '0', "delay_type": "",
                                    "day_type": "",
                                    "delay_hours": "00",
                                    "delay_date": ""
                                },
                                "extra": { 'srcHandle': src_handle, 'trgHandle': null, },
                                "extra_cond": {}
                            }
                        }
                        setNodes((nds) => nds.concat(node1));
                        setRefecther(!refecther)
                        setEdges((edg) => edg.concat(edge1));
                    }
                })
            } catch (error) {
                console.log(error)
            }
        } else if (channelVals[1] == "list") {
            try {
                axios.post("https://tickmaker.io/api/get-int-template-by-id", {
                    "auth_key": wa_authKey,
                    "template_id": channelVals[0].template_id,
                    "interactive_type": "list",
                }, headers).then(resp => {
                    if (resp.data.status == "success") {
                        let api = {
                            'campaign_name': channelVals[0].title,
                            'sender': "dgffgh",
                            'whatsapp_var': JSON.stringify({}),
                            'template_id': channelVals[0].template_id,
                            "auth_key": wa_authKey,
                            "whatsapp_type": "interactive",
                            "interactive_type": "list",
                            "interactive_btn": JSON.stringify(resp.data.data)
                        }
                        const node1 = {
                            'id': node_id,
                            'type': 'customnode',
                            'position': getPos(parent_node),
                            'data': {
                                'label': channelVals[0].title,
                                'value': {
                                    'parent': parent,
                                    'type': "whatsapp",
                                    'api': api,
                                    'description': "",
                                    "columns": parent_node.data.value.columns,
                                    "extra": channelVals[0]

                                }
                            }
                        }
                        const edge1 = {
                            'source': parent,
                            'target': node_id,
                            'sourceHandle': src_handle,
                            'targetHandle': null,
                            'id': `reactflow__edge-${parent}-${node_id}`,
                            'animated': false,
                            'type': "customedge",
                            'data': {
                                "delay": { "days": 0, "hours": 0, "minutes": 0 },
                                "label": cond,
                                "parent": parent_node,
                                "custom_condition": "",
                                "schedule": {
                                    "schType": '', 'days': "0", "dateType": "", "hours": '0', "mins": '0', "delay_type": "",
                                    "day_type": "",
                                    "delay_hours": "00",
                                    "delay_date": ""
                                },
                                "extra": { 'srcHandle': src_handle, 'trgHandle': null, },
                                "extra_cond": {}
                            }
                        }
                        setNodes((nds) => nds.concat(node1));
                        setEdges((edg) => edg.concat(edge1));

                    }
                })
            } catch (error) {
                console.log(error)
            }
        } else if (channelVals[1] == "template") {
            let api = {
                'campaign_name': channelVals[0].template_name,
                'sender': "dgffgh",
                'whatsapp_var': JSON.stringify({}),
                'template_id': channelVals[0].template_id,
                "auth_key": wa_authKey,
                "whatsapp_type": "template",
                "interactive_type": "button",
                "interactive_btn": ""
            }

            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': channelVals[0].template_name,
                    'value': {
                        'parent': parent,
                        'type': "whatsapp",
                        'api': api,
                        'description': "",
                        "extra": channelVals[0],
                        "columns": parent_node.data.value.columns
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "schedule": {
                        "schType": '', 'days': "0", "dateType": "", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null, },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));

            setEdges((edg) => edg.concat(edge1));
        } else if (channelVals[1] == "wa_message") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "Send Message",
                    'value': {
                        'parent': parent,
                        'type': "wa_message",
                        'api': {},
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        } else if (channelVals[1] == "agent_assign") {
            let api = {}
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "Agent Assign",
                    'value': {
                        'parent': parent,
                        'type': "agent_assign",
                        'api': api,
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        } else if (channelVals[1] == "on_message") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "On Message",
                    'value': {
                        'parent': parent,
                        'type': "on_message",
                        'api': { "keywords": {} },
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        } else if (channelVals[1] == "on_attribute") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "On Atrribute Change",
                    'value': {
                        'parent': parent,
                        'type': "on_attribute",
                        'api': {},
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        } else if (channelVals[1] == "sms") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "SMS",
                    'value': {
                        'parent': parent,
                        'type': "sms",
                        'api': {
                            "campaign_name": "SMS",
                            "sender": "",
                            "auth_key": bssp_authKey,
                            "route": "",
                            "template_id": "",
                            "coding": "",
                            "pe_id": "",
                            "content": "",
                        },
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }

            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        }

        else if (channelVals[1] == "voice") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "Voice",
                    'value': {
                        'parent': parent,
                        'type': "voice",
                        'api': {
                            "campaign_name": "Voice",
                            "sender": "",
                            "auth_key": bssp_authKey,
                            "route": "",
                            "voice_type": "",
                            "voice_duration": "",
                            "content": "",
                        },
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }

            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        }

        else if (channelVals[1] == "save_funnel") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "Save Funnel",
                    'value': {
                        'parent': parent,
                        'type': "save_funnel",
                        'api': { "funnelDetail": "" },
                        'description': "",
                        "columns": [],
                        "extra": {}
                    }
                }
            }

            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }


            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => {
                console.log(edg.concat(edge1))
                return edg.concat(edge1)
            });
        }


        else if (channelVals[1] == "wait_till") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "wait_till",
                    'value': {
                        'parent': parent,
                        'type': "wait_till",
                        'api': {},
                        'description': "",
                        "extra": channelVals[0],
                        "columns": parent_node.data.value.columns
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        }


        else if (channelVals[1] == "call_api") {
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "API Call",
                    'value': {
                        'parent': parent,
                        'type': "call_api",
                        'api': {},
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        }
        setRefecther(!refecther)

    }

    const addNewChannels = (channelVals) => {
        const type = channelVals[0]
        const parent_node = channelVals[1]
        const parent = channelVals[1].id
        const cond = channelVals[2]
        const src_handle = channelVals[3]
        const node_id = type + `-channel-${+Date.now()}`

        if (type == "button") {
            let api = {
                'campaign_name': "",
                'sender': "",
                'whatsapp_var': JSON.stringify({}),
                'template_id': "",
                "auth_key": wa_authKey,
                "whatsapp_type": "interactive",
                "interactive_type": "button",
                "interactive_btn": JSON.stringify([{ "title": "" }]),
                "ndsType": "new"
            }
            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "create New Btn",
                    'value': {
                        'parent': parent,
                        'type': "pre_whatsapp",
                        'api': api,
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null, },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        } else if (type == "list") {
            let api = {
                'campaign_name': "",
                'sender': "",
                'whatsapp_var': JSON.stringify({}),
                'template_id': "",
                "auth_key": wa_authKey,
                "whatsapp_type": "interactive",
                "interactive_type": "list",
                "interactive_btn": JSON.stringify({}),
                "intListData": JSON.stringify([{ "title": "", "rows": [{ "row_title": "", "row_description": "" }] }]),
                "ndsType": "new"
            }

            const node1 = {
                'id': node_id,
                'type': 'customnode',
                'position': getPos(parent_node),
                'data': {
                    'label': "create New List",
                    'value': {
                        'parent': parent,
                        'type': "pre_whatsapp",
                        'api': api,
                        'description': "",
                        "columns": parent_node.data.value.columns,
                        "extra": {}
                    }
                }
            }
            const edge1 = {
                'source': parent,
                'target': node_id,
                'sourceHandle': src_handle,
                'targetHandle': null,
                'id': `reactflow__edge-${parent}-${node_id}`,
                'animated': false,
                'type': "customedge",
                'data': {
                    "delay": { "days": 0, "hours": 0, "minutes": 0 },
                    "schedule": {
                        "schType": '', "dateType": "", 'days': "0", "hours": '0', "mins": '0', "delay_type": "",
                        "day_type": "",
                        "delay_hours": "00",
                        "delay_date": ""
                    },
                    "label": cond,
                    "parent": parent_node,
                    "custom_condition": "",
                    "extra": { 'srcHandle': src_handle, 'trgHandle': null },
                    "extra_cond": {}
                }
            }
            setNodes((nds) => nds.concat(node1));
            setEdges((edg) => edg.concat(edge1));
        }
        setRefecther(!refecther)

    }
    const getPos = (parents) => {
        let new_pos = { x: "100", y: "100" }
        function addPos(nds, parents) {
            const children = nds.filter(item => item.data.value.parent == parents.id);
            const childCount = children.length;
            return (childCount + 1) * 300;
        }

        setNodes((nds) => {
            new_pos.x = parents.xPos + addPos(nds, parents)
            return nds
        })
        new_pos.y = parents.yPos
        return new_pos
    }

    const InertList = ({ node_id, cond, handles, type }) => {
        const [inputText, setInputText] = useState("");
        let inputHandler = (e) => {
            var lowerCase = e.target.value.toLowerCase();
            setInputText(lowerCase);
        };


        const filteredData = waInterQuery.data.data.interactive.filter((el) => {
            if (inputText === '') {
                return el;
            } else {
                return el.title.toLowerCase().includes(inputText)
            }
        })

        if (type == "button") {
            return (
                <ul className="as_new">
                    <input placeholder='Search Template' className='form-control' onChange={inputHandler} />
                    <li onClick={() => addNewChannels(["button", node_id, cond, handles])}>Create new Template</li>
                    {
                        filteredData.map((vals) => {
                            if (vals.int_type == "button") {
                                if (vals.is_variable == 0) {
                                    return (<li onClick={() => addChannels([vals, "button", node_id, cond, handles])}>{vals.title}</li>)
                                } else {
                                    return (<li onClick={() => addChannels([vals, "button", node_id, cond, handles])}>{vals.title} (Var)</li>)
                                }
                            }
                        })
                    }
                </ul>
            )
        } else if (type == "list") {
            return (
                <ul className="as_new">
                    <input placeholder='Search Template' className='form-control' onChange={inputHandler} />
                    <li onClick={() => addNewChannels(["list", node_id, cond, handles])}>Create new Template</li>
                    {
                        filteredData.map((vals) => {

                            if (vals.int_type == "list") {
                                if (vals.is_variable == 0) {
                                    return (<li onClick={() => addChannels([vals, "list", node_id, cond, handles])}>{vals.title}</li>)
                                } else {
                                    return (<li onClick={() => addChannels([vals, "list", node_id, cond, handles])}>{vals.title} (Var)</li>)
                                }
                            }

                        })
                    }
                </ul>
            )
        }


    }
    const TempList = ({ node_id, cond, handles }) => {
        const [inputText, setInputText] = useState("");
        let inputHandler = (e) => {
            var lowerCase = e.target.value.toLowerCase();
            setInputText(lowerCase);
        };

        const filteredData = waTempQuery.data.data.template.filter((el) => {
            if (inputText === '') {
                return el;
            } else {
                return el.template_name.toLowerCase().includes(inputText)
            }
        })

        return (
            <ul className="as_new">
                <input placeholder='Search Template' className='form-control' onChange={inputHandler} />
                {
                    filteredData.map((vals) => {
                        if (vals.is_variable == 0) {
                            return (<li onClick={() => addChannels([vals, "template", node_id, cond, handles])}>{vals.template_name}</li>)
                        } else {
                            return (<li onClick={() => addChannels([vals, "template", node_id, cond, handles])}>{vals.template_name} (Var)</li>)
                        }
                    })
                }
            </ul>
        )
    }

    const SaveFunnel = ({ node_id, cond, handles }) => {
        const [inputText, setInputText] = useState("");
        let inputHandler = (e) => {
            var lowerCase = e.target.value.toLowerCase();
            setInputText(lowerCase);
        };

        const filteredData = funnelQuery.data.data.segment_list.filter((el) => {
            if (inputText === '') {
                return el;
            } else {
                return el.segment_name.toLowerCase().includes(inputText)
            }
        })
        const col_list = funnelQuery.data.data.col_list
        return (
            <ul className="as_new">
                <input placeholder='search' onChange={inputHandler} />
                {
                    filteredData.map((vals) => (<li onClick={() => addChannels([vals, "save_funnel", node_id, cond, handles, col_list])}>{vals.segment_name}</li>))
                }
            </ul>
        )
    }


    const items_ul2 = (node_id, cond, handles) => {
        return (
            <Fragment>
                <Menu.Item>
                    <Fragment>
                        <Menu>
                            <Menu.Button as="li" className="w-full text-left flex items-center outline-none as_btn_new">
                                Send Interactive<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </Menu.Button>
                            <Menu.Items>
                                <InertList node_id={node_id} cond={cond} handles={handles} type="button" />
                            </Menu.Items>
                        </Menu>
                    </Fragment>
                </Menu.Item>
                <Menu.Item>
                    <Fragment>
                        <Menu>
                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                Send Interactive List<i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </Menu.Button>
                            <Menu.Items>
                                <InertList node_id={node_id} cond={cond} handles={handles} type="list" />
                            </Menu.Items>
                        </Menu>
                    </Fragment>
                </Menu.Item>

                <Menu.Item>
                    <Fragment>
                        <Menu>
                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                Send Template <i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </Menu.Button>
                            <Menu.Items>
                                <TempList node_id={node_id} cond={cond} handles={handles} />
                            </Menu.Items>
                        </Menu>
                    </Fragment>
                </Menu.Item>


                {/* 
                <Menu.Item>
                    <Fragment>
                        <Menu>
                            <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new">
                                Funnel <i className="fa fa-angle-right ml-auto" aria-hidden="true"></i>
                            </Menu.Button>
                            <Menu.Items>
                                <SaveFunnel node_id={node_id} cond={cond} handles={handles} />
                            </Menu.Items>
                        </Menu>
                    </Fragment>
                </Menu.Item> */}

                <Menu.Item>
                    <li className="w-full text-left flex items-center outline-none as_btn_new" onClick={() => addChannels([[], "wa_message", node_id, cond, handles])}>Send Message</li>
                </Menu.Item>
                <Menu.Item>
                    <li className="w-full text-left flex items-center outline-none as_btn_new" onClick={() => addChannels([[], "agent_assign", node_id, cond, handles])}>Agent Assign</li>
                </Menu.Item>

                {/* ------------------------------------------------------------ */}
                <Menu.Item>
                    <li className="w-full text-left flex items-center outline-none as_btn_new" onClick={() => addChannels([[], "sms", node_id, cond, handles])}>Send SMS</li>
                </Menu.Item>
                <Menu.Item>
                    <li className="w-full text-left flex items-center outline-none as_btn_new" onClick={() => addChannels([[], "voice", node_id, cond, handles])}>Send Voice</li>
                </Menu.Item>
                <Menu.Item>
                    <li className="w-full text-left flex items-center outline-none as_btn_new" onClick={() => addChannels([[], "save_funnel", node_id, cond, handles])}>Save Funnel</li>
                </Menu.Item>

                {/* ------------------------------------------------------------ */}

                <Menu.Item>
                    <li className="w-full text-left flex items-center outline-none as_btn_new" onClick={() => addChannels([[], "on_message", node_id, cond, handles])}>On Message</li>
                </Menu.Item>
                <Menu.Item>
                    <li className="w-full text-left flex items-center outline-none as_btn_new" onClick={() => addChannels([[], "on_attribute", node_id, cond, handles])}>On Attribute Change</li>
                </Menu.Item>
            </Fragment>
        )
    }


    if (waTempQuery.isLoading || waInterQuery.isLoading || tempQuery.isLoading || webhookQuery.isLoading
        // || funnelQuery.isLoading
    ) {
        return (<div className="spin_ashish"><Spinner /></div>)
    }
    if (waTempQuery.isError || waInterQuery.isError || tempQuery.isError || webhookQuery.isError
        // || funnelQuery.isError
    ) {
        if (tempQuery.error != null) {
            if (tempQuery.error.response.status == '401') noAuth()
        }
    }

    // result

    const new_Channel = (typo) => {
        let status = true;
        nodes.map(nds => {
            if (nds.data.value.parent == 0) {
                status = false
            }
        })
        if (status == true) {
            let node1 = {}
            if (typo == "on_attribute") {
                const col_obj = [
                    {
                        "type": "on_attribute",
                        "details": {},
                        "column": [
                            { "column_name": "full_name" },
                            { "column_name": "email" },
                            { "column_name": "contact" },
                            { "column_name": "address" },
                            { "column_name": "date_of_birth" },
                            { "column_name": "company_name" },
                            { "column_name": "company_email" },
                            { "column_name": "status" },
                            { "column_name": "lead_type" },
                            { "column_name": "city" },
                            { "column_name": "state" },
                            { "column_name": "country" },
                            { "column_name": "zip_code" },
                        ],
                    }
                ]
                node1 = {
                    'id': `on_attribute-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "On Atrribute Change",
                        'value': { 'parent': 0, 'type': "on_attribute", 'api': { "attributeDetails": "" }, 'description': "", "columns": col_obj }
                    }
                }
            } else if (typo == "on_message") {
                const col_obj = [
                    {
                        "type": "on_message",
                        "details": {},
                        "column": [
                            // { "column_name": "profile_name" },
                            // { "column_name": "deliver_time" },
                            // { "column_name": "phone_number" },
                            { "column_name": "full_name" },
                            { "column_name": "email" },
                            { "column_name": "contact" },
                            { "column_name": "address" },
                            { "column_name": "date_of_birth" },
                            { "column_name": "company_name" },
                            { "column_name": "company_email" },
                            { "column_name": "status" },
                            { "column_name": "lead_type" },
                            { "column_name": "city" },
                            { "column_name": "state" },
                            { "column_name": "country" },
                            { "column_name": "zip_code" },
                        ],
                    }]
                node1 = {
                    'id': `on_message-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "On Message",
                        'value': { 'parent': 0, 'type': "on_message", 'api': { "keywords": {} }, 'description': "", "columns": col_obj }
                    }
                }
            } else if (typo == "on_webhook") {
                node1 = {
                    'id': `on_webhook-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "On Webhook",
                        'value': { 'parent': 0, 'type': "on_webhook", 'api': { "webhookDetail": "", "phone_number_col": "", Columns: [] }, 'description': "", "columns": {} }
                    }
                }
            } else if (typo == "on_funnel") {
                node1 = {
                    'id': `on_funnel-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "Funnel",
                        'value': { 'parent': 0, 'type': "on_funnel", 'api': { "funnelDetail": "", "phone_number_col": "" }, 'description': "", "columns": {} }
                    }
                }
            }
            else if (typo == "on_new_funnel") {
                node1 = {
                    'id': `on_new_funnel-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "Funnel",
                        'value': { 'parent': 0, 'type': "on_new_funnel", 'api': { "funnelDetail": "", "phone_number_col": "" }, 'description': "", "columns": {} }
                    }
                }
            }
            else if (typo == "on_edit_funnel") {
                node1 = {
                    'id': `on_edit_funnel-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "Funnel",
                        'value': { 'parent': 0, 'type': "on_edit_funnel", 'api': { "funnelDetail": "", "phone_number_col": "", "SchDetail": {} }, 'description': "", "columns": {} }
                    }
                }
            }
            else if (typo == "agent_assign") {
                node1 = {
                    'id': `agent_assign-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "Agent Assign",
                        'value': { 'parent': 0, 'type': "agent_assign", 'api': {}, 'description': "", "columns": {} }
                    }
                }
            }
            else if (typo == "on_spreedsheet") {
                node1 = {
                    'id': `on_spreedsheet-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "on_spreedsheet",
                        'value': { 'parent': 0, 'type': "on_spreedsheet", 'api': {}, 'description': "", "columns": {} }
                    }
                }
            }
            else if (typo == "google_sheet") {
                node1 = {
                    'id': `google_sheet-channel-${+Date.now()}`,
                    'type': 'customnode',
                    'position': { "x": 100, "y": 100 },
                    'data': {
                        'label': "Google Sheet",
                        'value': { 'parent': 0, 'type': "google_sheet", 'api': {}, 'description': "", "columns": {} }
                    }
                }
            }
            setNodes((nds) => nds.concat(node1));
        } else {
            toast.error("You can Add only one trigger")
        }
        setRefecther(!refecther)
    };

    // <li className="nav-item">
    //     <button type='button' className="nav-link" onClick={() => new_Channel("google_sheet")}>
    //         <i className="fa-solid fa-comments"></i>
    //         <span>Google Sheet</span>
    //     </button>
    // </li>
    const HeaderNav2 = () => {
        return (
            <ul className="navbar-nav bg-gradient-primary1  sidebar sidebar-dark accordion" id="accordionSidebar">
                <Link className="sidebar-brand d-flex align-items-center justify-content-center">
                    <div className="sidebar-brand-icon"><i className="fas fa-sitemap"></i></div>
                    <div className="sidebar-brand-text mx-3"> Oiomni</div>
                </Link>

                <div className='sidebar-heading'> <i className="fa-solid fa-list-check"></i> All Trigger </div>
                <hr className="sidebar-divider" />
                <li className="nav-item">
                    <button type='button' className="nav-link" onClick={() => new_Channel("on_message")}>
                        <i className="fa-solid fa-comments"></i>
                        <span>On Message</span>
                    </button>
                </li>
                <li className="nav-item">
                    <button type='button' className="nav-link" onClick={() => new_Channel("on_attribute")}>
                        <i className="fa-solid fa-user-pen"></i><span>On Attribute Change</span>
                    </button>
                </li>
                <li className="nav-item">
                    <button type='button' className="nav-link" onClick={() => new_Channel("agent_assign")}>
                        <i className="fa-solid fa-user-check"></i><span>On Agent Assign</span>
                    </button>
                </li>
                <li className="nav-item">
                    <button type='button' className="nav-link" onClick={() => new_Channel("on_funnel")}>
                        <i className="fa-solid fa-filter"></i><span>By Funnel</span>
                    </button>
                </li>
                <li className="nav-item">
                    <button type='button' className="nav-link" onClick={() => new_Channel("on_webhook")}>
                        <i className="fa-solid fa-sliders"></i><span>On Webhhok</span>
                    </button>
                </li>
                <li className="nav-item">
                    <button type='button' className="nav-link" onClick={() => new_Channel("on_spreedsheet")}>
                        <i className="fa-solid fa-file-lines"></i><span>On Spreedsheet</span>
                    </button>
                </li>
                <Menu>
                    <Menu.Button as='div' className="setting_menu1"> <i className=" icon_box_set fa-solid fa-gear"></i> <span> Settings </span> <i className="arro_right fa-solid fa-chevron-right"></i></Menu.Button>
                    <Menu.Items>
                        <ul className="as_new1_new p-0">
                            <Menu.Item>
                                <Fragment>
                                    <Menu as='div' className="setting_menu_ul">
                                        <Menu.Button as='div' className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/all_flow" className="nav-link"> <i className="icon_box_set fa-solid fa-list-check"></i>  <span> Bot List </span></Link>
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left flex setting_menu_li items-center outline-none ">
                                            <Link to="/all_flow" className="nav-link">    <i className="icon_box_set fa-regular fa-file-lines"></i>  <span> Bot Logs </span></Link>
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/webhook_setup" className="nav-link"> <i className="icon_box_set fa-solid fa-sliders"></i><span> Bot Webhook </span></Link>
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/profile" className="nav-link"> <i className="icon_box_set fa-regular fa-address-card"></i> <span> Profile</span></Link>
                                        </Menu.Button>
                                    </Menu>
                                </Fragment>
                            </Menu.Item>
                        </ul>
                    </Menu.Items>
                </Menu>


                <Menu>
                    <Menu.Button as='div' className="setting_menu1"><i className="icon_box_set fa-solid fa-hourglass-start"></i> <span> Coming Soon </span>  <i className="arro_right fa-solid fa-chevron-right"></i></Menu.Button>
                    <Menu.Items>
                        <ul className="as_new1_new p-0">
                            <Menu.Item>
                                <Fragment>
                                    <Menu as='div' className="setting_menu_ul">
                                        <Menu.Button as='div' className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/all_flow" className="nav-link">
                                                <i className="icon_box_set fa-solid fa-handshake"></i> <span> Deal Converstion  </span>
                                            </Link>
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left flex setting_menu_li items-center outline-none ">
                                            <Link to="/all_flow" className="nav-link">
                                                <i className="icon_box_set fa-solid fa-volume-high"></i> <span> On Voice Call </span>
                                            </Link>
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/webhook_setup" className="nav-link">  <i className="icon_box_set fa-solid fa-users-line"></i>
                                                <span> Spreed Sheet </span>
                                            </Link>
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/profile" className="nav-link"> <i className="icon_box_set fa-solid fa-handshake-slash"></i>
                                                <span> On Close Converstion </span>
                                            </Link>
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/profile" className="nav-link"> <i className="icon_box_set fa-brands fa-threads"></i>
                                                <span> Ads </span>
                                            </Link>
                                        </Menu.Button>
                                    </Menu>
                                </Fragment>
                            </Menu.Item>
                        </ul>
                    </Menu.Items>
                </Menu>
                <li className="nav-item">
                    <Link target="_blank" className="nav-link" to={"http://people.oiomni.com:8001/loginAs/" + auth_key}>
                        <i className="fa-solid fa-people-group"></i>
                        <span className="nav-text">People</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <button onClick={noAuth} className="nav-link">
                        <i className="fas fa-power-off"></i>
                        <span> Logout </span>
                    </button>
                </li>

                {/* 
                <Menu>
                    <Menu.Button>New Funnel Tri</Menu.Button>
                    <Menu.Items>
                        <ul className="as_new1">
                            <Menu.Item>
                                <Fragment>
                                    <Menu>
                                        <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new"
                                            onClick={() => new_Channel("on_new_funnel")}>
                                            New In Funnel
                                        </Menu.Button>
                                        <Menu.Button className="w-full text-left flex items-center outline-none as_btn_new"
                                            onClick={() => new_Channel("on_edit_funnel")}>
                                            Edit In Funnel
                                        </Menu.Button>
                                    </Menu>
                                </Fragment>
                            </Menu.Item>
                        </ul>
                    </Menu.Items>
                </Menu>
                */}
            </ul>
        )
    }


    const flowSave = (allDatas) => {
        onSave(allDatas)
    }

    const nameErr = () => {
        toast.error("Bot name is required")
        clearErrors("name")
    }

    const phoneErr = () => {
        toast.error("Phone Number must be required")
        clearErrors("phone_no_id")
    }
    const wa_preview = (datas) => {
        const Pre = () => {
            if (datas[1] == "button") {
                return (
                    <img src={ButtonImg} alt="Whatsapp List Preview" />
                )
            } else if (datas[1] == "list") {
                return (
                    <img src={ListImg} alt="Whatsapp List Preview" />
                )
            }
        }

        return (
            <div className="confirmation-popup">
                <div className="modal_as show-modal_as">
                    <div className="modal-content_as_side_pop">
                        <Pre />
                        <div className="text-center">
                            <button className="btn-sm btn-danger" onClick={() => setWaPreview([false, "", ""])}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <HeaderNav2 />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    {showConfirmation[0] && (
                        <div className="confirmation-popup">
                            <div className="modal_as show-modal_as">
                                <div className="modal-content_as">
                                    <p>Are you sure you want to delete this element?</p>
                                    <div className="text-center">
                                        <button className="btn btn-success mr-2" onClick={() => onDelNode(showConfirmation[1])}>Yes</button>
                                        <button className="btn-sm btn-danger" onClick={() => setShowConfirmation([false, ""])}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {waPreview[0] && wa_preview(waPreview)}
                    {flowPreview && onPreview()}


                    <ReactFlowProvider>
                        <div className="dndflow w-full" style={{ height: '100vh' }} ref={reactFlowWrapper}>
                            <div className="reactflow-wrapper h-full"  >
                                <div className="flex flex-row-reverse zindex_as">
                                    <form onSubmit={FlowSubmit(flowSave)}>
                                        <div className="bot_name_bg">
                                            <input
                                                {...flow("name", { required: true, value: flowDetail.flow_name })}
                                                defaultValue={flowDetail.flow_name}
                                                className='form-contol m-1 p-2' placeholder='Enter the Bot Name'
                                            />


                                            <select {...flow("phone_no_id", { required: true, value: flowDetail.wa_sender })} className='form-contol whait_bg_mobile m-1 p-2'>
                                                <option value="" selected disabled >Select Phone Number</option>
                                                {
                                                    waTempQuery.data.data.phone_details.map(i => {
                                                        return (
                                                            <option
                                                                selected={i.phone_no_id == flowDetail.wa_sender}
                                                                value={i.phone_no_id}
                                                            >{i.display_phone_no}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {flowErr.name && nameErr()}
                                            {flowErr.phone_no_id && phoneErr()}
                                            <button type="button" className="btn_as_my_wew ml-2" onClick={(e) => setFlowPreview(true)}> Preview </button>
                                            <button type="submit" className="btn_as_my_wew ml-2"> Save </button>
                                        </div>
                                    </form>
                                </div>
                                <ReactFlow
                                    nodes={nodes}
                                    edges={edges}
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    onInit={setRfInstance}
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onConnect={onConnect}
                                    defaultViewport={defaultViewport}
                                >
                                    <Background variant="cross" gap={20} size={4} color='#c4c4c4' style={{ 'backgroundColor': '#fff' }} />
                                    <Controls position="bottom-left" ></Controls>
                                </ReactFlow>

                            </div>
                        </div>
                        <ToastContainer />
                    </ReactFlowProvider>
                </div>
            </div>
            <DevTool control={FlowControl} />
        </Fragment>
    );
}