import React, { useState, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { useForm, useFieldArray, Controller } from "react-hook-form"
import DatePicker from "react-datepicker";
import SelectOptions from './SelectOptions';
const normalToOg = (arr) => {
    console.log(arr)

    
    if(Object.values(arr).length != 0){
        return arr.map((item, index) => ({ ...item, children: arr[index] }));
    }else{
        return []
    }
}

export default function CondComponet2({ allDatas, parent, condSave }) {
    const myInputRef = useRef(null);

    const { register, control, handleSubmit, reset, formState: { errors }, watch } = useForm({

        defaultValues: {
            condition: normalToOg(allDatas.data.extra_cond)
        }



    });
    const { fields, append, remove } = useFieldArray({ control, name: "condition" });
    const [rerender, setRerender] = useState(false);


    const allCond = [
        { "cond": "=", "cond_name": "Is" },
        { "cond": '!=', "cond_name": 'Is Not' },
        { "cond": '<', "cond_name": 'Less Than' },
        { "cond": '<=', "cond_name": 'Less or Equal' },
        { "cond": '>=', "cond_name": 'Greater or Equal' },
        { "cond": '>', "cond_name": 'Greater than' },


        { "cond": 'start_with', "cond_name": 'Start With' },
        { "cond": 'end_with', "cond_name": 'End With' },
        { "cond": 'contain', "cond_name": 'Contain' },
        { "cond": 'not_contain', "cond_name": 'Not contain' },
        { "cond": 'is_range', "cond_name": 'Is Range' },
        { "cond": 'is_not_range', "cond_name": 'Not in Range' },
        { "cond": 'before', "cond_name": 'Before' },
        { "cond": 'after', "cond_name": 'After' },
        { "cond": 'today', "cond_name": 'Today' },
        { "cond": 'is_date', "cond_name": 'Is date' },
        { "cond": 'is_not_date', "cond_name": 'Is Not Date' },
        { "cond": 'between_date', "cond_name": 'Is Date Between' },
        { "cond": 'not_between', "cond_name": 'Not Between' }
    ];





    // newColumn() defaultValues


    const Column = ({ val, parentIndex, childIndex }) => {
        // if (parent.data.value.columns.source.type == "on_funnel") {
        //     return (
        //         <Fragment>
        //             <div className="header_teml_1">
        //                 <label className="header_heading m-0 "> <i className="fa-solid fa-file-lines"></i> Column</label>
        //                 <div className="header_enter_text_box">
        //                     <select className="form-select"
        //                         {...register(`condition[${parentIndex}].children[${childIndex}].column`)}
        //                     >
        //                         <option selected value="" disabled>Select Condition Column</option>

        //                         {parent.data.value.columns.column.map(i => <option value={i.column_name}>{i.column_name}</option>)}
        //                     </select>
        //                 </div>
        //             </div>
        //         </Fragment>
        //     )
        // }                                 {parent.data.value.columns.column.map(i => <option value={i.field_name}>{i.field_name}</option>)}


        return (
            <Fragment>
                <div className="header_teml_1 mt-2">
                    <label className="header_heading m-0 "> <i className="fa-solid fa-file-lines"></i> Column</label>
                    <div className="header_enter_text_box">
                        <select className="form-select"
                            {...register(`condition[${parentIndex}].children[${childIndex}].column`)}
                        >
                            <option selected value="" disabled>Select Condition Column</option>
                            <SelectOptions colObj={parent.data.value.columns} />
                        </select>
                    </div>
                </div>
            </Fragment>
        )
    }

    const Condition = ({ val, parentIndex, childIndex }) => {
        const valId = `#cond_val_${parentIndex}_${childIndex}`

        const cond_typeChange = (vals) => {
            const main_box = myInputRef.current
            main_box.querySelector(valId).className = "col-md-12 mb-3"

            ReactDOM
                .createRoot(main_box.querySelector(valId))
                .render(
                    <CondVal
                        values={vals}
                        parentIndex={parentIndex}
                        childIndex={childIndex}
                    />
                );
        }
        if (val.value && val.value.length != 0) {
            console.log(val)
            console.log(valId)

            // cond_typeChange(val.value)
        }



        return (

            <div className="header_teml_1">
                <label className="header_heading m-0 "> <i class="fa-solid fa-network-wired"></i> Condition </label>
                <div className="header_enter_text_box">
                    <select className="form-select"
                        {...register(`condition[${parentIndex}].children[${childIndex}].condition`)}
                        onChange={(e) => cond_typeChange(e.target.value)}
                    >
                        <option value="" selected disabled> Select Condition</option>
                        {allCond.map(i => (<option value={i.cond}>{i.cond_name}</option>))}
                    </select>
                </div>
            </div>

        )
    }

    const Value = ({ val, parentIndex, childIndex }) => {
        if (val.value && val.value.length != 0) {
            console.log(val)
            return (
                <div id={`cond_val_${parentIndex}_${childIndex}`}>
                    <CondVal values={val.condition} parentIndex={parentIndex} childIndex={childIndex} />
                </div>
            )
        } else {
            return <div id={`cond_val_${parentIndex}_${childIndex}`}></div>
        }
    }

    const CondVal = ({ values, typo, parentIndex, childIndex }) => {
        if (values == "between_date" || values == "not_between") {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> From Date</label>
                            <div className="header_enter_text_box">
                                <Controller
                                    name={`condition[${parentIndex}].children[${childIndex}].value.range.0`}
                                    control={control}
                                    value={new Date()}
                                    defaultValue={new Date()}
                                    render={({ field }) => {
                                        return (<DatePicker
                                            className="form-control"
                                            selected={field.value}
                                            dateFormat="MMMM d, yyyy"
                                            onChange={(date) => field.onChange(date)}
                                        />
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">

                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-regular fa-calendar-days"></i> To Date</label>
                            <div className="header_enter_text_box">

                                <Controller
                                    name={`condition[${parentIndex}].children[${childIndex}].value.range.1`}
                                    control={control}
                                    value={new Date()}
                                    defaultValue={new Date()}
                                    render={({ field }) => {
                                        return (<DatePicker
                                            className="form-control"
                                            selected={field.value}
                                            dateFormat="MMMM d, yyyy"
                                            onChange={(date) => field.onChange(date)}
                                        />
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (values == "before" || values == "after") {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0"> <i class="fa-solid fa-calendar-day"></i> Enter Day</label>
                        <div className="header_enter_text_box">
                            <input
                                className="form-control"
                                placeholder="Enter Day"
                                {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                            />
                        </div>
                    </div>
                </Fragment>
            )

        } else if (values == "is_date" || values == "is_not_date") {
            return (
                <div className="header_teml_1">
                    <label className="header_heading m-0"> <i class="fa-solid fa-calendar-days"></i> Enter Date</label>
                    <div className="header_enter_text_box">
                        <Controller
                            name={`condition[${parentIndex}].children[${childIndex}].value.date`}
                            control={control}
                            value={new Date()}
                            defaultValue={new Date()}
                            render={({ field }) => {
                                return (<DatePicker
                                    className="form-control"
                                    selected={field.value}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    timeInputLabel="Time:"
                                    showTimeInput
                                    onChange={(date) => field.onChange(date)}
                                />
                                )
                            }}
                            rules={{ required: true }}
                        />
                    </div>
                </div>
            )
        } else if (values == "is_range" || values == "is_not_range") {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                            <div className="header_enter_text_box">
                                <input
                                    className="form-control"
                                    placeholder="Enter Value"
                                    {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="header_teml_1">
                            <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                            <div className="header_enter_text_box">
                                <input
                                    className="form-control"
                                    placeholder="Enter Value"
                                    {...register(`condition[${parentIndex}].children[${childIndex}].value.1`)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else if (values == "today" || values == "not_today") {
        } else {
            return (
                <Fragment>
                    <div className="header_teml_1">
                        <label className="header_heading m-0"> <i class="fa-solid fa-list-ol"></i> Enter Value</label>
                        <div className="header_enter_text_box">
                            <input
                                className="form-control"
                                placeholder="Enter Value"
                                {...register(`condition[${parentIndex}].children[${childIndex}].value.0`)}
                            />
                        </div>
                    </div>
                </Fragment>
            )
        }
    }



    const onSubmit = (formDatas) => {
        console.log(formDatas)
        const newArray = formDatas.condition.filter(item => item.children.length > 0).map(item => item.children);

        // condSave(newArray)
    }





    const addParent = () => {
        append({ children: [{ name: '' }] });
    };

    const removeChild = (parentIndex, childIndex) => {
        fields[parentIndex].children.splice(childIndex, 1);
        setRerender(!rerender);
    };

    const addChild = (parentIndex) => {
        fields[parentIndex].children.push({ column: '', condition: '' });
        setRerender(!rerender);
    };




    if (fields.length == 0) {
        return (
            <div className="edge_button">
                <button className="btn btn-sm text-whait" type='button' onClick={() => append({ children: [{ column: '', condition: '' }] })}>
                    <i className="fa-solid fa-plus"></i>
                </button>
            </div>
        )
    } else {
        return (
            <div className="card position-relative box_width" ref={myInputRef}>
                <div className="card-header p-2 Condition_bg">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="mt-2 font-weight-bold  temp_text_color"> <i class="fa-solid fa-network-wired"></i> Condition
                            </h6>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="card-body mt-0 p-2"  >
                        {fields.map((parent, parentIndex) => {

                            return (

                                <div className="fst_div_box ">
                                    <div className="new_cro1">
                                        <button type="button" onClick={() => remove(parentIndex)} className="btn btn-sm btn-danger ">
                                            <i class="fa-solid fa-xmark"></i>
                                        </button>
                                    </div>

                                    {parent.children.map((child, childIndex) => {

                                        return (
                                            <Fragment>

                                                {childIndex != 0 ? (<div className="or_div"><div className="line_top_or"></div><p>Or</p><div className="line_top_or"></div></div>) : ""}

                                                <div className="p-2use" key={`${parent.id}-${childIndex}`}>
                                                    <div className="one_div_box_new_add">
                                                        <div className="new_cro7">
                                                            <button
                                                                type="button"
                                                                onClick={() => removeChild(parentIndex, childIndex)}
                                                                className="btn btn-sm btn-danger new_cro3">
                                                                <i className="fa-regular fa-circle-xmark"></i>
                                                            </button>
                                                        </div>
                                                        <div className="row" id={`box${parentIndex}_${childIndex}`}>
                                                            <Column val={child} parentIndex={parentIndex} childIndex={childIndex} />
                                                            <Condition val={child} parentIndex={parentIndex} childIndex={childIndex} />
                                                            <Value val={child} parentIndex={parentIndex} childIndex={childIndex} />
                                                            <div id={`cond_val_${parentIndex}_${childIndex}`}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    }




                                    )}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="text-center ashish_flex_box">
                                                <div className="line_h_Ashish"> </div>
                                                <button type="button" className="btn_ashish btn-sm" onClick={() => addChild(parentIndex)}> OR </button>
                                                <div className="line_h_Ashish"> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }



                        )}



                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="text-center">
                                    <button className="btn btn-success btn-full" type='submit'>Save</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <div className="butt_po_As">
                                        <button className="Button_div_box_plu " type="button" onClick={() => append({ children: [{ name: '' }] })}>
                                            <i className="fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cl_ashish"></div>
                    </div>
                </form>
            </div>
        );
    }




}
