import React, { Fragment } from 'react';
import { Link, Routes, Route, redirect, useNavigate } from "react-router-dom";

import Login from './App/Auth/login.components'
import Signup from './App/Auth/signup.components'
import LoginAs from './App/Auth/login_as.components'

import { Menu } from '@headlessui/react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import AllFlow from './App/Flow/all_flow.components';
import GetFlow from './App/Flow/get_flow.components';
import TestGetFlow from './App/Flow/test_get_flow.components';
import NewGetFlow from './App/Flow/new_flow.component';
import NewBot from './App/Flow/new_bot.components';
import DemoPage from './App/Flow/demo_page.components';
import GetFlowTemplate from './App/Flow/get_flow_template.components';
import SpreadsheetList from './App/Flow/testing.components';
import NewOne from './App/Flow/newFlows/test';
import Facebook from './App/Flow/newFlows/facebook';
import Report from './App/Report/report.components';
import Campaign from './App/Report/campaign.components';
import Log from './App/Report/log.components';
import Statistics from './App/Report/statistics.components';
import Profile from './App/Report/profile.components';
import WebhookSetup from './App/Group/webhook_setup.components';
import LogInstant from './App/Report/log_instant.components';
import BtnList from './App/Report/btn_list.components';
import NewReport from './App/Report/new_report.components';
import TempData from './App/Report/temp_data.components';
import CampStatistics from './App/Report/camp_statistics.components';
import Template from './App/Group/template.components';
import NoRoute from './App/Group/no_route.components';
import BsspSetup from './App/Group/bssp_setup.components';



const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');


const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    window.location.href = '/';
}

const HeaderNav = () => {
    return (
        <Fragment>
            <ul className="navbar-nav bg-gradient-primary1 sidebar sidebar-dark accordion" id="accordionSidebar">

                <Link className="sidebar-brand d-flex align-items-center justify-content-center">
                    <div class="sidebar-brand-icon">
                        <i class="fas fa-sitemap"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3"> Oiomni </div>
                </Link>

                <div className='sidebar-heading'> <i className="fa-solid fa-list-check"></i> Main Menu </div>
                <hr className="sidebar-divider" />

                <li className="nav-item">
                    <Link to="/new" className="nav-link">
                        <i className="fas fa-sitemap"></i>
                        <span>New Bot</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/all_flow" className="nav-link">
                        <i className="fa-solid fa-list-check"></i>
                        <span>Bot List</span>
                    </Link>
                </li>


              


                <li className="nav-item">
                    <a target="_blank" className="nav-link" rel="noreferrer" href="/bssp_setup">
                        <i className="fa fa-users" aria-hidden="true"></i>
                        <span className="nav-text">Bssp</span>
                    </a>
                </li>

                <li className="nav-item">
                    <a target="_blank" className="nav-link" rel="noreferrer" href={"http://people.oiomni.com:8001/loginAs/" + auth_key}>
                        <i className="fa fa-users" aria-hidden="true"></i>
                        <span className="nav-text">People</span>
                    </a>
                </li>

                <Menu>
                    <Menu.Button as='div' className="setting_menu1"> <i class=" icon_box_set fa-solid fa-gear"></i> <span> Settings </span> <i class="arro_right fa-solid fa-chevron-right"></i></Menu.Button>
                    <Menu.Items>
                        <ul className="as_new1_new p-0">
                            <Menu.Item>
                                <Fragment>
                                    <Menu as='div' className="setting_menu_ul">

                                        <Menu.Button as='div' className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/all_flow" className="nav-link"> <i className="icon_box_set fa-solid fa-list-check"></i>  <span> Bot List </span></Link>
                                        </Menu.Button>

                                        <Menu.Button className="w-full text-left flex setting_menu_li items-center outline-none ">
                                            <Link to="/all_flow" className="nav-link">    <i className="icon_box_set fa-regular fa-file-lines"></i>  <span> Bot Logs </span></Link>
                                        </Menu.Button>

                                        <Menu.Button className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/webhook_setup" className="nav-link"> <i className="icon_box_set fa-solid fa-sliders"></i><span> Bot Webhook </span></Link>
                                        </Menu.Button>

                                        <Menu.Button className="w-full text-left setting_menu_li flex items-center outline-none ">
                                            <Link to="/profile" className="nav-link"> <i className="icon_box_set fa-regular fa-address-card"></i> <span> Profile</span></Link>
                                        </Menu.Button>
                                    </Menu>
                                </Fragment>
                            </Menu.Item>
                        </ul>
                    </Menu.Items>
                </Menu>
                <li className="nav-item">
                    <button onClick={logOut} className="nav-link">
                        <i className="fas fa-power-off"></i>
                        <span> Logout </span>
                    </button>
                </li>
            </ul>
        </Fragment>
    )
}



const Includer1 = (props) => {
    const queryClient = new QueryClient();

    return (
        <Fragment>
            <HeaderNav />
            <QueryClientProvider client={queryClient}>

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <props.cont />
                    </div>
                </div>
            </QueryClientProvider>

        </Fragment>
    )
}

const Includer2 = (props) => {
    const queryClient3 = new QueryClient();


    return (<QueryClientProvider client={queryClient3}><props.cont /></QueryClientProvider>

    )
}




export default function App() {
    const queryClient2 = new QueryClient();

    if (!!token) {
        return (
            <div id="wrapper">
                <Routes>
                    <Route exact path="/loginas/:id" element={<Includer1 cont={LoginAs} />} />
                    <Route exact path="/Facebook" element={<Facebook />} />
                    <Route exact path="/new_report/:id" element={<Includer1 cont={NewReport} />} />
                    <Route exact path="/profile" element={<Includer1 cont={Profile} />} />
                    <Route exact path="/all_flow" element={<Includer1 cont={AllFlow} />} />
                    <Route exact path="/new_bot" element={<Includer1 cont={NewBot} />} />
                    <Route exact path="/demoPage" element={<Includer1 cont={DemoPage} />} />
                    <Route exact path="/webhook_setup" element={<Includer1 cont={WebhookSetup} />} />
                    <Route exact path="/Template" element={<Includer1 cont={Template} />} />
                    <Route exact path="/new_acc" element={<Includer1 cont={SpreadsheetList} />} />
                    <Route exact path="/report/:id" element={<Includer1 cont={Report} />} />
                    <Route exact path="/campaign/:id" element={<Includer1 cont={Campaign} />} />
                    <Route exact path="/log/:id" element={<Includer1 cont={Log} />} />
                    <Route exact path="/statistics/:id" element={<Includer1 cont={Statistics} />} />
                    <Route exact path="/temp_data/:id" element={<Includer1 cont={TempData} />} />
                    <Route exact path="/log_instant/:id" element={<Includer1 cont={LogInstant} />} />
                    <Route exact path="/button_list/:id" element={<Includer1 cont={BtnList} />} />
                    <Route exact path="/camp_statistics/:id" element={<Includer1 cont={CampStatistics} />} />
                    <Route exact path="/bssp_setup" element={<Includer1 cont={BsspSetup} />} />

                    

                    <Route exact path="/flow/:id" element={<Includer2 cont={NewGetFlow} />} />
                    <Route exact path="/testflow/:id" element={<Includer2 cont={TestGetFlow} />} />
                    <Route exact path="/new" element={<Includer2 cont={NewOne} />} />
                    <Route exact path="/new/:id" element={<Includer2 cont={NewOne} />} />
                    <Route exact path="/flow_template/:id" element={<GetFlowTemplate />} />

                    <Route path="*" element={<NoRoute />} />
                </Routes>
            </div>
        )
    } else {
        return (
            <QueryClientProvider client={queryClient2}>
                <Fragment>
                    <Routes>
                        <Route exact path="/" element={<Login />} />
                        <Route exact path="/signup" element={<Signup />} />
                        <Route exact path="/loginas/:id" element={<LoginAs />} />
                    </Routes>
                </Fragment>
            </QueryClientProvider>
        )
    }
}