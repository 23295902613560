
/* eslint-disable */
import React, { useState, Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useForm, useFieldArray } from "react-hook-form"

import Spinner from '../cutomComponent/spinner'

const host = "https://oiomni.com/api"
const token = localStorage.getItem('token');
const user_id = localStorage.getItem('user_id');
const auth_key = localStorage.getItem('auth_key');
const wa_authKey = localStorage.getItem('wa_auth_key');

const noAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('length');
    localStorage.removeItem('wa_auth_key');
    window.location.href = '/';
};



const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}



export default function BsspSetup() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm({});
    const { register: createReg, handleSubmit:createHandle , reset: createReset, formState: { errors: createErr } } = useForm({});
    const bsspSetupQuery = useQuery({
        queryKey: ['bsspSetup'],
        queryFn: async () => {
            try {
                const response = await axios.get(host + "/third_party_list/bssp", headers);
                console.log(response.data)
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },
    })

    if (!token) noAuth()

    if (bsspSetupQuery.isLoading) return <Spinner />;
    if (bsspSetupQuery.isError) {
        if (bsspSetupQuery.error != null) {
            if (bsspSetupQuery.error.response.status == '401') noAuth()
        }
    }

    const onSave = (formDatas) => {
        console.log(formDatas)
        if (formDatas.type == "bssp") {

            axios.post("https://sms.bulksmsserviceproviders.com/api/auth-checking", formDatas, headers)
                .then(response => {
                    if (response.status == 200) {
                        const newFormData = {
                            "app_auth_key": response.data.data.auth_key,
                            "app_username": response.data.data.username,
                            "app_user_id": response.data.data.id,
                            "app_name": "bssp",
                        }

                        axios.post(host + "/third_party_setup", newFormData, headers).then(res => {
                            if (res.status == 200) {
                                reset()
                                remove()
                                bsspSetupQuery.refetch()
                                toast.success(response.data.message)
                            }
                        })
                            .catch(err => {
                                if (err.response) {
                                    Object.values(err.response.data.message).map(i => {
                                        i.map(k => {
                                            toast.error(k)
                                        })
                                    })
                                } else if (err.request) {
                                    toast.error('No response received from the server.');
                                } else {
                                    toast.error(`${err.message}`);
                                }
                            })
                    }
                })
                .catch(err => {
                    if (err.response) {
                        toast.error(err.response.data.message);
                    } else if (err.request) {
                        toast.error('No response received from the server.');
                    } else {
                        toast.error(`${err.message}`);
                    }
                })
        }
    };

    const createAcc = (formDatas) => {
        console.log("hello")
    }

    if (bsspSetupQuery.data.length != 0) {
        if (bsspSetupQuery.data.app_name = "bssp") {
            // return window.location.href = "https://sms.bulksmsserviceproviders.com/phnbk/adminlogin/" + bsspSetupQuery.data.app_username + "t5Ng"
        }
    }





    return (
        <Fragment>
            <div className="container-fluid">
                <h1 className="h3_ashish mb-4 text-gray-800"> <i className="fa-brands fa-slack"></i>Login By Authenticate Key </h1>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card position-relative">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    <i className="fa-solid fa-file-signature"></i>Authenticate Key
                                </h6>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSave)}>
                                    <div className="mx-auto">
                                        <input {...register(`auth_key`, { required: true })} placeholder={`Enter Authenticate Key`} className='form-control' />
                                        {errors.auth_key && (<span className="font-sans text-xs text-red-800 p-1">Authenticate Key is required</span>)}
                                        <input type="hidden" defaultValue="bssp" {...register(`type`, { required: true })} />
                                        <button className="btn btn-success" type="submit">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card position-relative">
                            <div className="card-header py-3">
                                <p className="m-0 text-primary">If you don't have account on BSSP then just create new account from here. Just Click Create Account Button</p>
                            </div>
                            <div className="card-body">
                                <form onSubmit={createHandle(createAcc)}>
                                    <div className="mx-auto">
                                        <button className="btn btn-success" type="submit">Create Account</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    );
}