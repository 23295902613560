import React, { useState, Fragment } from 'react';
import { useForm, useFieldArray } from "react-hook-form"
import axios from 'axios';

const headers = {
    "headers": {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'mode': 'no-cors',
    }
}
let searchTerm = /{{\d+}}/g;
const buttonCounts = (data) => {
    if (data) {

        return data.length
    }
}


const btnsDeg = (data) => {
    if (data) {
        const transformedData = {};
        let btnTitle = "";
        data.forEach(item => {
            if (btnTitle == "") {
                btnTitle = item.button_title
            }

            const sectionTitle = item.section_title;
            if (!transformedData[sectionTitle]) {
                transformedData[sectionTitle] = {
                    title: sectionTitle,
                    section_no: item.section_no,
                    rows: []
                };
            }

            transformedData[sectionTitle].rows.push({
                row_title: item.row_title,
                row_no: item.row_no,
                row_id: item.row_id,
                row_description: item.row_desc
            });
        });

        return { "action": Object.values(transformedData), "btn_title": btnTitle };
    }
}

const cntFun = (data) => {
    if(data && data != null){
        let matches = data.match(searchTerm);
        if (matches && matches != null){
            return matches.length
        }else{
            return 0
        }
    }else{
        return 0
    }

}

export default function EditListTemp({ nodeId, datas, showError, setNodes, setEdges, setLoad, load }) {
    const { register, control, handleSubmit, clearErrors, watch, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            ...btnsDeg(JSON.parse(datas.api.interactive_btn)),
            "template_name": datas.extra.title,
            "header": datas.extra.header_data,
            "body": datas.extra.body,
            "footer": datas.extra.footer,

        }
    });
    const { fields, append, remove } = useFieldArray({ control, name: "action" });

    const [headerCount, setHeaderCount] = useState(cntFun(getValues("header")))
    const [bodyCount, setBodyCount] = useState(cntFun(getValues("body")))
    const [btnCount, setBtnCount] = useState(buttonCounts(JSON.parse(datas.api.interactive_btn)));



    const onSubmit = (data) => {
        if (btnCount != 0) {
            // console.log(data)
            // console.log(datas)
            // onSave(data)


            const newForm = {
                "int_type": "list",
                "header_type": "text",
                "header_data": data.header,
                "body": data.body,
                "footer": data.footer,
                "template_name": data.template_name,
                "auth_key": localStorage.getItem('wa_auth_key'),
                "action": data.action,
                "btn_title": data.btn_title,
                "template_id": datas.api.template_id,
            }
            // console.log(newForm)
            try {
                axios.post("https://work.secureip.in/api/edit-interactive-template", newForm, headers).then(response => {

                    if (response.data.status == "success") {
                        try {
                            axios.post("https://tickmaker.io/api/get-int-template-by-id", {
                                "auth_key": localStorage.getItem('wa_auth_key'),
                                "template_id": response.data.data.template_id,
                                "interactive_type": "list",
                            }, headers).then(res => {
                                // console.log(res)
                                if (res.data.status == "success") {
                                    console.log(res.data.data)

                                    function edgeCorrect(btnD) {

                                        setEdges(eds => eds.map(edge => {
                                            if (edge.source == nodeId) {

                                                JSON.parse(btnD).map(btnVal => {

                                                    const srcHand = edge.sourceHandle.split('-')
                                                    console.log(srcHand)
                                                    if (btnVal.row_title == edge.sourceHandle.split('-')[1]) {
                                                        res.data.data.map(btn_i => {
                                                            if (btn_i.row_id == btnVal.row_id) {
                                                                console.log()
                                                                edge.data.label = btn_i.row_title + " List(Button)"
                                                                edge.data.extra.srcHandle = `${srcHand[0]}-${btn_i.row_title}-${srcHand[2]}`



                                                                edge.sourceHandle = `${srcHand[0]}-${btn_i.row_title}-${srcHand[2]}`
                                                            }
                                                        })
                                                    }
                                                })
                                                console.log(edge)
                                            }
                                            return edge;
                                        }))
                                    }

                                    setNodes(nds => nds.map(node => {
                                        if (node.id == nodeId) {
                                            const nodeVal = node.data.value
                                            node.data.label = data.template_name
                                            nodeVal.type = "whatsapp"
                                            nodeVal.api.ndsType = "edit"
                                            let extras = {
                                                "template_id": response.data.data.template_id,
                                                "title": data.template_name,
                                                "int_type": "list",
                                                "header_type": "text",
                                                "header_data": data.header,
                                                "file_name": null,
                                                "body": data.body,
                                                "footer": data.footer,
                                                "is_variable": "0"
                                            }
                                            edgeCorrect(nodeVal.api.interactive_btn)
                                            nodeVal.api.interactive_btn = JSON.stringify(res.data.data)
                                            nodeVal.extra = extras
                                        }
                                        return node;
                                    }))
                                    setLoad(!load)
                                } else {
                                    console.log(response)
                                }
                                console.log(response.data.message)
                                // toast.success(response.data.message)
                            })
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        console.log(response)
                    }
                    console.log(response.data.message)
                    // toast.success(response.data.message)
                })
            } catch (error) {
                console.log(error)
                // throw new Error('Failed to fetch day from API');
            }



        } else {
            showError("There should be atleast one Button")
        }
    }



    const errFun = (type, msg) => {
        if (type == "body") {
            if (errors.body.type == "required") {
                showError("The Body field is required")
            } else if (errors.body.type == "maxLength") {
                return ("Max Length of Body Feild is 1000")
            }
        } else if (type == "template_name") {
            if (errors.template_name.type == "required") {
                showError("The Template Name is required")
            } else if (errors.template_name.type == "maxLength") {
                return ("Max Length of Template Name  Feild is 512")
            }
        }
        else if (type == "footer") {
            if (errors.footer.type == "maxLength") {
                return ("Max Length of Footer is 50")
            }
        } else if (type == "btn_title") {
            if (errors.btn_title.type == "required") {
                showError("The Butoon Title is required")
            } else if (errors.btn_title.type == "maxLength") {
                return ("Max Length of Button Title Feild is 20")
            }
        }

        clearErrors(type)
    }

    const newErrFun = (type, parent_index, msg) => {
        if (errors && errors.action) {
            console.log(errors.action)
            if (errors.action[`${parent_index}`].title) {
                if (errors.action[`${parent_index}`].title.type == "required") {
                    
                    return (<div className="meg_lat_new">{msg}</div>)
                } else if (errors.action[`${parent_index}`].title.type == "maxLength") {
                    return (<div className="meg_lat_new">Max Length of Section Title Feild is 20</div>)
                }
            }
        }
    }
    const newErrRowFun = (type, parent_index, child_index, msg) => {
        if (errors && errors.action && errors.action[`${parent_index}`]) {
            const rowErr = errors.action[`${parent_index}`].rows
            const rowFeild = fields[`${parent_index}`].rows

            if (rowErr) {
                if (rowErr[`${child_index}`].row_title && type == "row_title") {
                    if (rowErr[`${child_index}`].row_title.type == "required") {
                        return (<div className="meg_lat_new">{msg}</div>)
                    } else if (rowErr[`${child_index}`].row_title.type == "maxLength") {
                        return (<div className="meg_lat_new">Max Length of Option Title Feild is 20</div>)
                    }
                } else if (rowErr[`${child_index}`].row_description && type == "row_description") {
                    if (rowErr[`${child_index}`].row_description.type == "required") {
                        return (<div className="meg_lat_new">{msg}</div>)
                    } else if (rowErr[`${child_index}`].row_description.type == "maxLength") {
                        return (<div className="meg_lat_new">Max Length of Option Description Feild is 70</div>)
                    }
                }
            }
        }
    }

    const cancelForm = () => {
        setNodes(nds => nds.map(node => {
            if (node.id == nodeId) {
                node.data.value.type = "whatsapp"
            }
            return node;
        }))

        setLoad(!load)
    }


    const BodyFun = () => {
        const addVar = () => {
            if (bodyCount < 20) {
                const currentValue = getValues('body');
                const newValue = currentValue + "{{" + (bodyCount + 1) + "}}";
                setValue('body', newValue.toString());
                setValue('is_variable', "1");
                setBodyCount(bodyCount + 1)
            } else {
                showError("You can add only 20 variable in Body")
            }
        }
        return (
            <Fragment>
                <textarea maxLength={1000} {...register("body", { required: true, maxLength: 1000 })} className="form-control" placeholder="Enter Body" />

                <div className="text-right"> <button className="btn btn-sm ad_var_btn" type='button' onClick={(e) => addVar()} ><i className="fa fa-plus"></i> Var </button></div>
                {errors.body && errFun("body", "Body field is required")}
            </Fragment>
        )
    }

    const HeaderFun = () => {

        const addVar = () => {
            if (headerCount < 1) {
                const currentValue = getValues('header');
                const newValue = currentValue + "{{" + (headerCount + 1) + "}}";
                setValue('header', newValue.toString());
                setValue('is_variable', "1");
                setHeaderCount(headerCount + 1)
            } else {
                showError("You can add only one variable in header")
            }


        }
        return (
            <Fragment>
                {headerCount}
                <input {...register("header")} className="form-control" placeholder="Enter Header" />
                <div className="text-right"> <button className="btn btn-sm ad_var_btn" type='button' onClick={(e) => addVar()} ><i className="fa fa-plus"></i> Var </button></div>
            </Fragment>
        )
    }


    const sectionTitle = (parentIndex) => {

        const varCount = getValues(`action[${parentIndex}].title`).length
        return (
            <div>
                {newErrFun("title", parentIndex, "Section Title is required")}
                <input maxLength={20}
                    className='form-control myinputcss' placeholder='Section Title'  {...register(`action[${parentIndex}].title`, {
                        required: true, maxLength: 20
                    })} />
                <p className="cer_cont">{20 - varCount} / 20 </p>
            </div>
        )
    }

    const rowBox = (parentIndex, childIndex) => {
        const titleCount = getValues(`action[${parentIndex}].rows[${childIndex}].row_title`).length
        const descCount = getValues(`action[${parentIndex}].rows[${childIndex}].row_description`).length


        return (
            <div>

                {newErrRowFun("row_title", parentIndex, childIndex, "Option Title is required")}
                <input maxLength={20} className='form-control myinputcss mb-1' placeholder='Option Title'
                    {...register(`action[${parentIndex}].rows[${childIndex}].row_title`, { required: true, maxLength: 20 })}
                />
                <p className="cer_cont">{20 - titleCount} / 20</p>


                {newErrRowFun("row_description", parentIndex, childIndex, "Option Description is required")}
                <textarea maxLength={70} className='form-control myinputcss mb-1' placeholder='Option Description'
                    {...register(`action[${parentIndex}].rows[${childIndex}].row_description`, { required: true, maxLength: 70 })}
                />
                <p className="cer_cont">{70 - descCount} / 70</p>

            </div>
        )
    }

    const gettingLen = (name) => {
        if (getValues(name) && getValues(name) != null){
            return getValues(name).length
        }else{
            return 0
        }
    }



    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <input {...register("nodeId", { required: true })} value={nodeId} type='hidden' />
                <input {...register("waInterType", { required: true })} value="list" type='hidden' />
                <input {...register("is_variable", { required: true })} value="0" type='hidden' />


                <div className="header_teml">
                    <label className="header_heading"><i className="fa-regular fa-address-card"></i> Tempalte Name </label>
                    <div className="header_enter_text_box">
                        <input maxLength={512} {...register("template_name", { required: true })} className="form-control" placeholder="Enter Tempalte Name " />
                        {errors.template_name && errFun("template_name", "Template Name is required")}

                        
                    </div>
                    <p className="cer_cont">{512 - gettingLen(`template_name`)} / 512</p>
                </div>

                <div className="header_teml">
                    <label className="header_heading"> <i className="fa-solid fa-tachograph-digital"></i> Header</label>
                    <div className="header_enter_text_box">
                        <HeaderFun />
                    </div>
                    <p className="cer_cont">{gettingLen(`header`)} </p>
                </div>

                <div className="body_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-file-lines"></i> Body</label>
                    <div className="body_enter_text_box">
                        <BodyFun />
                    </div>
                    <p className="cer_cont">{1000 - gettingLen(`body`)} / 1000</p>
                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Footer</label>
                    <div className="footer_enter_text_box">
                        <input maxLength={50} {...register("footer", { maxLength: 50 })} className="form-control" placeholder="Enter Footer" />
                    </div>
                    <p className="cer_cont">{50 - gettingLen(`footer`)} / 50</p>
                </div>

                <div className="footer_teml mt-2">
                    <label className="header_heading"> <i className="fa-solid fa-table-list"></i> Button Title</label>
                    <div className="footer_enter_text_box">
                        <input maxLength={20} {...register("btn_title", { required: true, maxLength: 20 })} className="form-control" placeholder="Enter Button Title" />
                        {errors.btn_title && errFun("btn_title", "Button Title is required")}
                        
                    </div>
                    <p className="cer_cont">{20 - gettingLen(`btn_title`)} / 20</p>
                </div>

                {fields.map((parent, parentIndex) => (
                    <div className="card-body mt-0 p-2 " key={parent.id}>
                        <div className="fst_div_box">
                            {sectionTitle(parentIndex)}
                            {parent.rows.map((child, childIndex) => (
                                <div className="p-2" key={`${parent.id}-${childIndex}`}>
                                    <div className="one_div_box_new_add ">
                                        {rowBox(parentIndex, childIndex)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="line_ashish"> </div>

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="text-center">
                            <button className="btn btn-success btn-full" type='submit'>Edit</button>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="text-center">
                            <button className="btn btn-danger btn-full" type='button' onClick={(e) => cancelForm()}>Cancel</button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )

}